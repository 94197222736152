import React, { MouseEvent, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'
import MuiPaper from '@material-ui/core/Paper'
import MuiBadge from '@material-ui/core/Badge'
import MuiPopper from '@material-ui/core/Popper'
import MuiDivider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MuiNotificationsIcon from '@material-ui/icons/Notifications'

import {
  acceptInvite,
  declineInvite,
  getInvitations,
  openInvitationsSelector,
} from 'src/store/invitationSlice'
import { breakpoints, Routes } from 'src/utils/constants'
import InviteItem from 'src/components/notifications/InviteItem'
import { getErrorToast, getSuccessToast, openToast } from 'src/store/toastSlice'
import {
  getNotifications,
  markNotificationAsRead,
  unreadImprovementOpportunityNotificationsSelector,
  unreadPracticeAddedNotificationsSelector,
  unreadRoundNotificationsSelector,
} from '../../store/notificationSlice'
import NotificationItem from './NotificationItem'
import { useHistory } from 'react-router-dom'
import useAppState from '../../hooks/useAppState'
import { Notification, NotificationType } from '../../models/notification'
import { getPlayer } from '../../store/playerSlice'
import { getRounds, updateSelectedRoundByUuid } from '../../store/roundSlice'
import {
  getPractices,
  setSelectedPracticeByUuid,
} from '../../store/practiceSlice'
import { updateDialogVisibility } from '../../store/ioSlice'

const I18N_KEY = 'Notifications'

const NotificationListContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`

const Popper = styled(MuiPopper)(
  ({ theme }) => css`
    z-index: 1100;

    ${theme.breakpoints.up('xs')} {
      margin: ${theme.spacing(0, 0.5, 0, 1.5)};
    }
  `
)

const Paper = styled(MuiPaper)(
  ({ theme }) => css`
    padding: ${theme.spacing(2, 2, 2, 2)};
    position: relative;
    transform: translateY(${theme.spacing(1)}px);
  `
)

const Divider = styled(MuiDivider)(
  ({ theme }) => css`
    margin: ${theme.spacing(1, 0, 1, 0)};
  `
)

const NotificationsIcon = styled(MuiNotificationsIcon)`
  font-size: 1rem;
`

const Badge = styled(MuiBadge)(
  ({ theme }) => css`
    .MuiBadge-badge {
      border-radius: 14px;
      border: 2px solid ${theme.palette.background.black};
      height: 14px;
      min-width: 14px;
      margin-right: -2px;
      margin-top: -2px;
    }
  `
)

const NotificationsButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: 100%;
    padding: ${theme.spacing(1.5)}px;
    margin: ${theme.spacing(0, 1.5, 0)};
    background-color: ${theme.palette.background.darkGrey};

    @media (hover: hover) {
      &:hover {
        background-color: ${theme.palette.background.darkestGrey};
      }
    }

    svg {
      color: ${theme.palette.background.paper};
    }

    ${theme.breakpoints.up(breakpoints.mobile)} {
      margin: ${theme.spacing(0, 1.5, 0, 0)};
      margin-left: auto;
    }
  `
)

const Notifications: React.FC = () => {
  const { t } = useTranslation()

  const appState = useAppState()
  const dispatch = useDispatch()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const invites = useSelector(openInvitationsSelector)
  const roundNotifications = useSelector(unreadRoundNotificationsSelector)
  const improvementOpportunitiesNotifications = useSelector(
    unreadImprovementOpportunityNotificationsSelector
  )
  const practiceAddedNotification = useSelector(
    unreadPracticeAddedNotificationsSelector
  )
  useEffect(() => {
    dispatch(getInvitations())
    dispatch(getNotifications())
  }, [dispatch])

  const handleNotificationButtonClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAccept = async (uuid: string) => {
    try {
      await dispatch(acceptInvite(uuid))
      dispatch(
        openToast(
          getSuccessToast(
            t(
              `${I18N_KEY}.acceptInvitationSuccess`,
              'Invitation has been accepted'
            )
          )
        )
      )
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(
            t(
              `${I18N_KEY}.acceptInvitationError`,
              'Error accepting the invitation'
            )
          )
        )
      )
    }
  }
  const handleDecline = async (uuid: string) => {
    try {
      await dispatch(declineInvite(uuid))
      dispatch(
        openToast(
          getSuccessToast(
            t(
              `${I18N_KEY}.declineInvitationSuccess`,
              'Invitation has been declined'
            )
          )
        )
      )
    } catch (error: any) {
      dispatch(
        openToast(
          getSuccessToast(
            t(
              `${I18N_KEY}.declineInvitationError`,
              'Error declining invitation'
            )
          )
        )
      )
    }
  }

  const isBadgeVisible =
    (appState.isCoach &&
      (roundNotifications?.length ||
        improvementOpportunitiesNotifications?.length ||
        practiceAddedNotification?.length ||
        invites?.length)) ||
    (appState.isPlayer &&
      (improvementOpportunitiesNotifications?.length || invites?.length))

  const getNotificationRedirectURL = async (
    notification: Notification
  ): Promise<string> => {
    switch (notification.type) {
      case NotificationType.ROUND_ADDED:
        await dispatch(getRounds())
        dispatch(
          updateSelectedRoundByUuid({
            selectedRoundUuid: notification.attributes.roundUuid as string,
          })
        )
        return Routes.CoachPlayerRoundDetail.replace(
          ':playerUuid',
          (notification.attributes.playerUuid as string) ?? ''
        ).replace(
          ':roundUuid',
          (notification.attributes.roundUuid as string) ?? ''
        )
      case NotificationType.PRACTICE_ADDED:
        await dispatch(getPractices())
        dispatch(
          setSelectedPracticeByUuid({
            uuid: notification.attributes.practiceUuid as string,
          })
        )
        return Routes.CoachPlayerPracticeDetail.replace(
          ':playerUuid',
          (notification.attributes.playerUuid as string) ?? ''
        ).replace(
          ':practiceUuid',
          (notification.attributes.practiceUuid as string) ?? ''
        )
      case NotificationType.IMPROVEMENT_OPPORTUNITY:
        return appState.isPlayer
          ? Routes.Insight
          : Routes.CoachPlayerDash.replace(
              ':playerUuid',
              (notification.attributes.playerUuid as string) ?? ''
            )
      case NotificationType.IMPROVEMENT_OPPORTUNITY_AVAILABLE:
        return appState.isPlayer
          ? Routes.Insight
          : Routes.CoachPlayerInsight.replace(
              ':playerUuid',
              (notification.attributes.playerUuid as string) ?? ''
            )
      default:
        return ''
    }
  }

  const onUnreadNotificationClick = async (
    notification: Notification
  ): Promise<void> => {
    await dispatch(getPlayer(notification.attributes.playerUuid as string))
    const redirectURL: string = await getNotificationRedirectURL(notification)
    await dispatch(markNotificationAsRead(notification.uuid))
    if (
      notification.type === NotificationType.IMPROVEMENT_OPPORTUNITY_AVAILABLE
    ) {
      dispatch(updateDialogVisibility({ isOpen: true }))
    }
    history.push(redirectURL)
  }

  return (
    <>
      <NotificationsButton
        onClick={handleNotificationButtonClick}
        data-cy="notification-button"
      >
        <Badge
          color="error"
          variant="dot"
          invisible={!isBadgeVisible}
          overlap="rectangular"
        >
          <NotificationsIcon />
        </Badge>
      </NotificationsButton>
      <Popper open={!!anchorEl} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            <Typography variant="caption" color="textSecondary">
              <Trans i18nKey={`${I18N_KEY}.invitationMenuTitle`}>
                Invitations
              </Trans>
            </Typography>
            {!!invites?.length && (
              <NotificationListContainer>
                {invites?.map((invite, index) => (
                  <Box key={invite.uuid}>
                    {index > 0 && <Divider />}
                    <InviteItem
                      invite={invite}
                      onAccept={handleAccept}
                      onDecline={handleDecline}
                    />
                  </Box>
                ))}
              </NotificationListContainer>
            )}
            {!invites?.length && (
              <Box p={1}>
                <Typography color="textSecondary" variant="subtitle2">
                  <Trans i18nKey={`${I18N_KEY}.noNotifications`}>
                    You have no notifications
                  </Trans>
                </Typography>
              </Box>
            )}
            <Typography variant="caption" color="textSecondary">
              <Trans i18nKey={`${I18N_KEY}.improvementOpportunityMenuTitle`}>
                Improvement Opportunity Notification
              </Trans>
            </Typography>
            {!!improvementOpportunitiesNotifications?.length && (
              <NotificationListContainer>
                {improvementOpportunitiesNotifications?.map(
                  (notification: Notification) => (
                    <NotificationItem
                      key={notification.uuid}
                      notification={notification}
                      handleClick={() =>
                        onUnreadNotificationClick(notification)
                      }
                    />
                  )
                )}
              </NotificationListContainer>
            )}
            {!improvementOpportunitiesNotifications?.length && (
              <Box p={1}>
                <Typography color="textSecondary" variant="subtitle2">
                  <Trans i18nKey={`${I18N_KEY}.noNotifications`}>
                    You have no notifications
                  </Trans>
                </Typography>
              </Box>
            )}
            {appState.isCoach ? (
              <>
                <Typography variant="caption" color="textSecondary">
                  <Trans i18nKey={`${I18N_KEY}.roundAddedMenuTitle`}>
                    Round Added Notification
                  </Trans>
                </Typography>
                {!!roundNotifications?.length && (
                  <NotificationListContainer>
                    {roundNotifications?.map((notification: Notification) => (
                      <>
                        <NotificationItem
                          key={notification.uuid}
                          notification={notification}
                          handleClick={() =>
                            onUnreadNotificationClick(notification)
                          }
                        />
                      </>
                    ))}
                  </NotificationListContainer>
                )}
                {!roundNotifications?.length && (
                  <Box p={1}>
                    <Typography color="textSecondary" variant="subtitle2">
                      <Trans i18nKey={`${I18N_KEY}.noNotifications`}>
                        You have no notifications
                      </Trans>
                    </Typography>
                  </Box>
                )}

                <Typography variant="caption" color="textSecondary">
                  <Trans i18nKey={`${I18N_KEY}.practiceAddedMenuTitle`}>
                    Practice Added Notification
                  </Trans>
                </Typography>
                {!!practiceAddedNotification?.length && (
                  <NotificationListContainer>
                    {practiceAddedNotification?.map(
                      (notification: Notification) => (
                        <>
                          <NotificationItem
                            key={notification.uuid}
                            notification={notification}
                            handleClick={() =>
                              onUnreadNotificationClick(notification)
                            }
                          />
                        </>
                      )
                    )}
                  </NotificationListContainer>
                )}
                {!practiceAddedNotification?.length && (
                  <Box p={1}>
                    <Typography color="textSecondary" variant="subtitle2">
                      <Trans i18nKey={`${I18N_KEY}.noNotifications`}>
                        You have no notifications
                      </Trans>
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              ''
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default Notifications

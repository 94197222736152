import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Form, Formik, FormikConfig } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import styled, { css } from 'styled-components'
import MuiContainer from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import {
  breakpoints,
  Languages,
  Routes,
  TimeFilter,
  TypeFilter,
  WhereFilter,
} from '../../../../utils/constants'
import {
  BenchmarkId,
  FEMALE_PRO_BENCHMARK_SELECTION,
  FEMALE_SCRATCH_BENCHMARKS,
  MALE_PRO_BENCHMARK_SELECTION,
  MALE_SCRATCH_BENCHMARKS,
} from '../../../../utils/golfConstants'
import { CustomTimeRange } from '../../../../components/inputs/CustomDatePicker'
import { playerSelector } from '../../../../store/playerSlice'
import { Dialog, Toolbar, XsPaddingZeroContainer } from '../../../common'
import LoaderButton from '../../../../components/inputs/LoaderButton'
import RoundSelectionFilter from '../../../../components/filter/RoundSelectionFilter'
import Grid from '@material-ui/core/Grid'
import FormSelect from '../../../../components/inputs/FormSelect'
import {
  createDiscussionDocumentReport,
  DiscussionDocumentRequestBody,
  isOpenSelector,
  requestIsInProgress,
  updateDialogVisibility,
} from '../../../../store/discussionDocumentSlice'
import { getReports } from '../../../../store/reportSlice'
import { useHistory } from 'react-router-dom'

const Container = styled(MuiContainer)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(5)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(5, 2, 0)};
    }

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 0, 0)};
    }
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing(6)}px;
    width: 350px;
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `
)

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    width: 650px;
    padding: ${theme.spacing(6, 4)};
    margin: ${theme.spacing(4, 0, 0)};

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }

    ${theme.breakpoints.down('xs')} {
      margin: 0;
      padding: ${theme.spacing(4, 2)};
    }
  `
)

export const MainContentBackground = styled.div(
  ({ theme }) => css`
    flex: 1;
    width: 100%;
    background-color: ${theme.palette.background.xlight};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(2, 0)};
    }
  `
)

const SectionTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(1)}px;
  `
)

const DialogTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(56)};
    line-height: ${theme.typography.pxToRem(72)};

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.pxToRem(40)};
      line-height: ${theme.typography.pxToRem(48)};
      margin-bottom: ${theme.spacing(2)}px;
    }
  `
)

const TextContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
)

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)}px;
  `
)

const StyledFormSelect = styled(FormSelect)`
  margin-top: 6px;
`

interface Filters {
  type: TypeFilter
  time: TimeFilter
  where: WhereFilter
  baseBenchmarkId: BenchmarkId
  stretchBenchmarkId: BenchmarkId
  language: Languages
  customTimeRange: CustomTimeRange | undefined
}

const I18N_KEY = 'DiscussionDocumentGeneration'

const DiscussionDocumentGeneration: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { uuid, gender } = useSelector(playerSelector)

  const { benchmarkId } = useSelector(playerSelector)

  const requestInProgress = useSelector(requestIsInProgress)

  const isOpen = useSelector(isOpenSelector)
  // const roundCount = useSelector(totalRoundsSelector)

  const closeDialog = () => {
    dispatch(updateDialogVisibility({ isOpen: false }))
  }

  const handleBaseBenchmarkChange =
    (setFieldValue: (field: string, value: any) => void) =>
    (e: React.ChangeEvent<{ value: unknown }>) => {
      const selectedValue = e.target.value
      setFieldValue('baseBenchmarkId', selectedValue)
      setFieldValue('stretchBenchmarkId', selectedValue)
    }

  const benchmarkOptions = () => {
    const benchmarks =
      gender === 'male'
        ? [...MALE_SCRATCH_BENCHMARKS, ...MALE_PRO_BENCHMARK_SELECTION]
        : [...FEMALE_SCRATCH_BENCHMARKS, ...FEMALE_PRO_BENCHMARK_SELECTION]

    return benchmarks.map((benchmark, index) => ({
      label: t(`Enums:Benchmark.${benchmark}`),
      value: benchmark,
      index,
      disabled: false,
    }))
  }

  const filteredBenchmarkOptions = (selectedBaseBenchmarkId?: BenchmarkId) => {
    const updatedBenchmarkOptions = benchmarkOptions()

    const selectedBenchmarkOption = updatedBenchmarkOptions.find(
      benchmarkOption => benchmarkOption.value === selectedBaseBenchmarkId
    )

    updatedBenchmarkOptions.forEach(benchmarkOption => {
      benchmarkOption.disabled = selectedBenchmarkOption
        ? benchmarkOption.index < selectedBenchmarkOption.index
        : false
    })

    return updatedBenchmarkOptions
  }

  // Commented out until we have Chinese translation
  // const showLanguages = () => {
  //   const languages = [Languages.English, Languages.Chinese]
  //   return Object.values(languages).map(language => {
  //     return {
  //       label: t(`Enums:Language.${language}`),
  //       value: language,
  //     }
  //   })
  // }

  const formikOptions: FormikConfig<Filters> = {
    initialValues: {
      type: TypeFilter.Both,
      time: TimeFilter.LastFive,
      where: WhereFilter.Outdoor,
      baseBenchmarkId: benchmarkId,
      stretchBenchmarkId: benchmarkId,
      language: Languages.English,
      customTimeRange: undefined,
    },
    enableReinitialize: true,
    onSubmit: async ({
      type,
      time,
      where,
      baseBenchmarkId,
      stretchBenchmarkId,
      customTimeRange,
    }) => {
      // if (roundCount > 0) {
      // Remove random id created for animation
      const payload: DiscussionDocumentRequestBody = {
        type,
        time,
        where,
        customTimeRange,
        baseBenchmarkId,
        stretchBenchmarkId,
        language: Languages.English,
      }
      await dispatch(createDiscussionDocumentReport(payload))
      dispatch(getReports())
      history.push(Routes.CoachPlayerReports.replace(':playerUuid', uuid ?? ''))
      // } else {
      //   dispatch(
      //     openToast(
      //       getErrorToast(
      //         'No rounds for selected filters to generate Discussion Document'
      //       )
      //     )
      //   )
      // }
    },
  }

  return (
    <Dialog open={isOpen}>
      <AppBar elevation={0} color="default" position="relative">
        <XsPaddingZeroContainer>
          <Toolbar>
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Toolbar>
          <TextContainer>
            <DialogTitle variant="h1">
              <Trans i18nKey={`${I18N_KEY}.title`}>
                Generate Discussion Document
              </Trans>
            </DialogTitle>
          </TextContainer>
        </XsPaddingZeroContainer>
      </AppBar>
      <MainContentBackground>
        <Container>
          <StyledPaper elevation={0}>
            <SectionTitle variant="h4">
              <Trans i18nKey={`${I18N_KEY}.filters`}>
                Select following values
              </Trans>
            </SectionTitle>
            <Formik {...formikOptions}>
              {({ setFieldValue, values }) => (
                <Form>
                  <Grid>
                    <RoundSelectionFilter updateRoundCount />
                    <StyledGrid item xs={12} sm={6}>
                      <Typography variant="caption">
                        <Trans i18nKey={`${I18N_KEY}.baseBenchmark`}>
                          Benchmark
                        </Trans>
                      </Typography>
                      <StyledFormSelect
                        fullWidth
                        options={benchmarkOptions()}
                        showErrorString={false}
                        name="baseBenchmarkId"
                        onChange={handleBaseBenchmarkChange(setFieldValue)}
                      />
                    </StyledGrid>
                    <StyledGrid item xs={12} sm={6}>
                      <Typography variant="caption">
                        <Trans i18nKey={`${I18N_KEY}.stretchBenchmark`}>
                          Benchmark
                        </Trans>
                      </Typography>
                      <StyledFormSelect
                        fullWidth
                        options={filteredBenchmarkOptions(
                          values.baseBenchmarkId
                        )}
                        showErrorString={false}
                        name="stretchBenchmarkId"
                      />
                    </StyledGrid>
                    {/* Commented out until we have Chinese translation */}
                    {/* <StyledGrid item xs={12} sm={6}> */}
                    {/*  <Typography variant="caption"> */}
                    {/*    <Trans i18nKey={`${I18N_KEY}.language`}> */}
                    {/*      Benchmark */}
                    {/*    </Trans> */}
                    {/*  </Typography> */}
                    {/*  <StyledFormSelect */}
                    {/*    fullWidth */}
                    {/*    options={showLanguages()} */}
                    {/*    showErrorString={false} */}
                    {/*    name="language" */}
                    {/*  /> */}
                    {/* </StyledGrid> */}
                  </Grid>
                  <ButtonContainer>
                    <LoaderButton
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={requestInProgress}
                    >
                      <Trans i18nKey={`${I18N_KEY}.generate`}>Generate</Trans>
                    </LoaderButton>
                  </ButtonContainer>
                </Form>
              )}
            </Formik>
          </StyledPaper>
        </Container>
      </MainContentBackground>
    </Dialog>
  )
}

export default DiscussionDocumentGeneration

import React, { useMemo, useCallback, useContext } from 'react'
import { Locale } from 'date-fns'
import dateFnsFormat from 'date-fns/format'
import { useTranslation } from 'react-i18next'
import dateFnsLocalZh from 'date-fns/locale/zh-CN'
import dateFnsLocalEn from 'date-fns/locale/en-GB'
import { TFunction } from 'i18next'

import {
  Greeting,
  Languages,
  DateFormat,
  DateFormats,
  DefaultLanguage,
  DateFormatsObject,
  Unit,
} from 'src/utils/constants'
import {
  MetricId,
  ActivityId,
  BenchmarkId,
  CategoryType,
  ReferencePoint,
  MALE_PRO_REFERENCED_BENCHMARKS,
  FEMALE_PRO_REFERENCED_BENCHMARKS,
} from 'src/utils/golfConstants'
import { convertYardsToMetersForLabels } from './helpers'
import { LanguageContext } from './LanguageProvider'
import { unitSelector } from 'src/store/playerSlice'
import { useSelector } from 'react-redux'

interface Translation {
  locale: Locale
  metricLabels: LabelObject
  formats: DateFormatsObject
  activityLabels: LabelObject
  categoryLabels: LabelObject
  greetingLabels: LabelObject
  measurementLabels: LabelObject
  rangeInstructions: LabelListObject
  courseInstructions: LabelListObject
  getBenchmarkName: (id?: string) => any
  format: (date: number | Date, formatKey: DateFormats) => string
  getReferencePoint: (
    benchmarkId: BenchmarkId | undefined | null,
    isMale: boolean
  ) => any
  t: TFunction
}

interface LabelObject {
  [k: string]: string
}

interface LabelListObject {
  [k: string]: string[]
}

export const TranslationContext = React.createContext<Translation | null>(null)

enum TranslatableMeasurements {
  MPH = 'mph',
  Feet = 'feet',
  Yards = 'yards',
  Meters = 'meters',
  StrokesGained = 'sg',
  PuttsOfTen = 'putts_out_of_ten',
}

const GreetingKeys = Object.values(Greeting)
const MetricIdKeys = Object.values(MetricId)
const ActivityKeys = Object.values(ActivityId)
const CategoryKeys = Object.values(CategoryType)
const MeasurementKeys = Object.values(TranslatableMeasurements)

const TranslationProvider: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation()
  const { language } = useContext(LanguageContext)
  const unit = useSelector(unitSelector)

  const getBenchmarkName = useCallback(
    (id?: string) => t(`Enums:Benchmark.${id}`, ''),
    [t]
  )

  const getReferencePoint = useCallback(
    (benchmarkId: BenchmarkId | undefined | null, isMale: boolean) => {
      if (!benchmarkId) {
        return ''
      }

      if (isMale) {
        return MALE_PRO_REFERENCED_BENCHMARKS.some(id => id === benchmarkId)
          ? t(`Enums:ReferencePoint.${ReferencePoint.PGA}`, '')
          : t(`Enums:ReferencePoint.${ReferencePoint.MaleScratch}`, '')
      } else {
        return FEMALE_PRO_REFERENCED_BENCHMARKS.some(id => id === benchmarkId)
          ? t(`Enums:ReferencePoint.${ReferencePoint.LPGA}`, '')
          : t(`Enums:ReferencePoint.${ReferencePoint.FemaleScratch}`, '')
      }
    },
    [t]
  )

  const metricLabels: LabelObject = useMemo(
    () =>
      MetricIdKeys.reduce(
        (labels, id) => ({
          ...labels,
          [id]:
            unit === Unit.Metric
              ? convertYardsToMetersForLabels(
                  t(`Enums:MetricLabels.${id}`),
                  language
                )
              : t(`Enums:MetricLabels.${id}`),
        }),
        {}
      ),
    [t, language, unit]
  )

  const categoryLabels: LabelObject = useMemo(
    () =>
      CategoryKeys.reduce(
        (labels, type) => ({
          ...labels,
          [type]: t(`Enums:CategoryType.${type}`),
        }),
        {}
      ),
    [t]
  )

  const activityLabels: LabelObject = useMemo(
    () =>
      ActivityKeys.reduce(
        (labels, type) => ({
          ...labels,
          [type]:
            unit === Unit.Metric
              ? convertYardsToMetersForLabels(
                  t(`Enums:Activity.${type}`),
                  language
                )
              : t(`Enums:Activity.${type}`),
        }),
        {}
      ),
    [t, language, unit]
  )

  const courseInstructions: LabelListObject = useMemo(
    () =>
      ActivityKeys.reduce(
        (labels, type) => ({
          ...labels,
          [type]: t(`Enums:ActivityCourseInstructions.${type}`, {
            returnObjects: true,
          }),
        }),
        {}
      ),
    [t]
  )

  const rangeInstructions: LabelListObject = useMemo(
    () =>
      ActivityKeys.reduce(
        (labels, type) => ({
          ...labels,
          [type]: t(`Enums:ActivityRangeInstructions.${type}`, {
            returnObjects: true,
          }),
        }),
        {}
      ),
    [t]
  )

  const measurementLabels: LabelObject = useMemo(
    () =>
      MeasurementKeys.reduce(
        (labels, type) => ({
          ...labels,
          [type]: t(`Enums:Measurement.${type}`),
        }),
        {}
      ),
    [t]
  )

  const greetingLabels: LabelObject = useMemo(
    () =>
      GreetingKeys.reduce(
        (labels, type) => ({
          ...labels,
          [type]: t(`Enums:Greeting.${type}`),
        }),
        {}
      ),
    [t]
  )

  //   Date Formatting
  const locale = useMemo(() => {
    switch (i18n.language) {
      case Languages.Chinese:
        return dateFnsLocalZh
      default:
        return dateFnsLocalEn
    }
  }, [i18n.language])

  const formats = useMemo(() => {
    return DateFormat[(i18n.language || DefaultLanguage) as Languages]
  }, [i18n.language])

  const format = useCallback(
    (date: Date | number, formatKey: DateFormats): string => {
      const dateFormat = formats[formatKey] || formats.default
      return dateFnsFormat(date, dateFormat, { locale })
    },
    [formats, locale]
  )

  return (
    <TranslationContext.Provider
      value={{
        format,
        locale,
        formats,
        metricLabels,
        activityLabels,
        categoryLabels,
        greetingLabels,
        getBenchmarkName,
        getReferencePoint,
        measurementLabels,
        rangeInstructions,
        courseInstructions,
        t,
      }}
    >
      {children}
    </TranslationContext.Provider>
  )
}

export default TranslationProvider

import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Hole, HolesNumber } from 'src/utils/golfConstants'
import { openToast, getErrorToast } from 'src/store/toastSlice'
import { selectedRoundSelector, saveHole } from 'src/store/roundSlice'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import { MembershipLevel } from 'src/utils/constants'

const useAddShots = (teeLayupFlag: boolean) => {
  const isPlayerPremium = useSelector(
    hasActiveSubscription(MembershipLevel.Basic)
  )
  const isPlayerTrialing = useSelector(userIsTrialingSelector)
  const [currentHole, setCurrentHole] = useState(0)
  const [transitionDirection, setTransitionDirection] = useState(1)

  const roundData = useSelector(selectedRoundSelector)

  const numberOfHoles = roundData?.numberOfHoles === HolesNumber.Nine ? 9 : 18

  const dispatch = useDispatch()

  const getNextHole = useCallback(
    async (values?: Hole) => {
      try {
        transitionDirection < 0 && setTransitionDirection(1)
        if (!(isPlayerPremium || isPlayerTrialing)) {
          dispatch(
            openToast(
              getErrorToast(
                'Error: An active subscription is required to edit a round'
              )
            )
          )
          return
        }
        values && (await dispatch(saveHole(currentHole, values, teeLayupFlag)))

        setCurrentHole(current => current + 1)
      } catch (error: any) {
        dispatch(openToast(getErrorToast(error.message)))
      }
    },
    [
      transitionDirection,
      dispatch,
      currentHole,
      teeLayupFlag,
      isPlayerPremium,
      isPlayerTrialing,
    ]
  )

  const getPreviousHole = useCallback(() => {
    transitionDirection > 0 && setTransitionDirection(-1)
    setCurrentHole(current => current - 1)
  }, [transitionDirection])

  return {
    roundData,
    currentHole,
    getNextHole,
    numberOfHoles,
    setCurrentHole,
    getPreviousHole,
    transitionDirection,
    setTransitionDirection,
  }
}

export default useAddShots

import React from 'react'
import { FieldArray } from 'formik'
import styled, { css } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Add from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import InfoIcon from '@material-ui/icons/Info'
import Typography from '@material-ui/core/Typography'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'

import { Shot, Par } from 'src/utils/golfConstants'
import ShotStep from 'src/modules/rounds/shots/ShotStep'
import Tooltip from 'src/components/dataDisplay/Tooltip'
import FixedStep from 'src/modules/rounds/shots/FixedStep'
import { breakpoints } from 'src/utils/constants'

const I18N_KEY = 'ShotFieldStepper'

interface Props {
  shots: Shot[]
  par: Par
  lieOptions: { label: string; value: string }[]
  isEditDisabled: boolean
}

const SectionLabel = styled(Typography)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      margin-left: ${theme.spacing(0.5)}px;
    }
  `
)

const StepList = styled.ul(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(8)}px;
  `
)

const AddShotButton = styled(Button)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`

const AddIcon = styled(Add)(
  ({ theme }) => css`
    top: 20px;
    left: -36px;
    width: 18px;
    position: absolute;
    color: ${theme.palette.primary.main};
    ${theme.breakpoints.down(breakpoints.mobile)} {
      left: -22px;
    }
  `
)

const FlagIcon = styled(GolfCourseIcon)(
  ({ theme }) => css`
    top: 22px;
    left: -34px;
    width: 16px;
    position: absolute;
    color: ${theme.palette.background.grey};
    ${theme.breakpoints.down(breakpoints.mobile)} {
      left: -19px;
    }
  `
)

const ShotFieldStepper: React.FC<Props> = ({
  shots,
  par,
  lieOptions,
  isEditDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <FieldArray name="shots">
      {({ push, remove }) => {
        const addShot = () => {
          push({ uuid: Math.random(), lie: '', distance: '' })
        }

        return (
          <Box mt={4}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Tooltip
                  arrow
                  title={
                    t(
                      `${I18N_KEY}.startingLieTooltipTitle`,
                      'Where you took this shot from'
                    ) as string
                  }
                  description={
                    t(
                      `${I18N_KEY}.startingLieTooltipDescription`,
                      'The starting lie is the area of the course that you took the shot from.'
                    ) as string
                  }
                >
                  <SectionLabel color="textSecondary" variant="caption">
                    <Trans i18nKey={`${I18N_KEY}.staringLieLabel`}>
                      Starting lie
                    </Trans>
                    <InfoIcon />
                  </SectionLabel>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip
                  arrow
                  title={
                    t(
                      `${I18N_KEY}.lengthTooltipTitle`,
                      'Starting length to hole'
                    ) as string
                  }
                  description={
                    t(
                      `${I18N_KEY}.lengthTooltipDescription`,
                      'The length to the hole from where you took this shot. '
                    ) as string
                  }
                >
                  <SectionLabel color="textSecondary" variant="caption">
                    <Trans i18nKey={`${I18N_KEY}.lengthLabel`}>Length</Trans>
                    <InfoIcon />
                  </SectionLabel>
                </Tooltip>
              </Grid>
            </Grid>
            <StepList>
              {shots.map((shot, index) => (
                <ShotStep
                  shot={shot}
                  lieOptions={lieOptions}
                  par={par}
                  index={index}
                  remove={remove}
                  key={shot.uuid || index}
                  isEditDisabled={isEditDisabled}
                />
              ))}
              {!isEditDisabled && (
                <FixedStep key="add-shot" icon={AddIcon}>
                  <Box height="60px" display="flex" alignItems="center">
                    <AddShotButton
                      color="primary"
                      onClick={addShot}
                      data-cy="add-shot"
                    >
                      <Trans i18nKey={`${I18N_KEY}.addShotButtonLabel`}>
                        Add shot
                      </Trans>
                    </AddShotButton>
                  </Box>
                </FixedStep>
              )}
              <FixedStep key="flag-display" showLine={false} icon={FlagIcon}>
                <Box
                  pl={2}
                  height="60px"
                  display="flex"
                  borderRadius={6}
                  alignItems="center"
                  bgcolor="background.xlight"
                >
                  <Typography color="textPrimary">
                    <Trans i18nKey={`${I18N_KEY}.inTheHole`}>In the hole</Trans>
                  </Typography>
                </Box>
              </FixedStep>
            </StepList>
          </Box>
        )
      }}
    </FieldArray>
  )
}

export default ShotFieldStepper

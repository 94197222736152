import React, { useMemo, useContext } from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { DateFormats, breakpoints } from 'src/utils/constants'
import { Activity, ValueGo } from 'src/utils/golfConstants'
import ScoreCard, {
  ScoreCardItemType,
} from 'src/components/dataDisplay/ScoreCard'
import { TranslationContext } from 'src/utils/TranslationContext'
import { Description, HeaderSection } from 'src/modules/practice/common'
import { SummaryContainer, FadingDotIllustration } from 'src/modules/common'
import PracticeSummaryTable from 'src/modules/practice/summary/PracticeSummaryTable'

const I18N_KEY = 'PracticeSummary'

interface Props {
  date?: Date
  activities: Activity[]
}

const StyledFadingDotIllustration = styled(FadingDotIllustration)(
  ({ theme }) => css`
    ${theme.breakpoints.down(breakpoints.mobile)} {
      display: none;
    }
  `
)

export const SummaryDescription = styled(Typography)`
  max-width: 555px;
`

const Summary: React.FC<Props> = ({ date, activities }) => {
  const { t } = useTranslation()
  const { format } = useContext(TranslationContext)!

  // const activities = useMemo(
  //   () => sections?.flatMap((section: Section) => section.activities) ?? [],
  //   [sections]
  // )

  const completedActivities = useMemo(
    () => activities?.filter(({ input }) => input || input === 0),
    [activities]
  )

  const completedActivitiesCount = completedActivities?.length

  const activitiesAboveGoal = useMemo(
    () =>
      completedActivities?.filter(
        activity =>
          activity.inputDifference! >= 0 && typeof activity.target === 'number'
      ).length,
    [completedActivities]
  )

  const activitiesBelowGoal = useMemo(
    () =>
      completedActivities?.filter(
        activity =>
          activity.inputDifference! < 0 && typeof activity.target === 'number'
      ).length,
    [completedActivities]
  )

  const activitiesNoGoal =
    completedActivitiesCount - activitiesAboveGoal - activitiesBelowGoal

  const activitiesAboveCourse = useMemo(
    () =>
      completedActivities?.reduce((acc, cur) => {
        if (cur.go! === ValueGo.Above && cur.input! >= cur.currentValue!) {
          return acc + 1
        }

        if (cur.go! === ValueGo.Below && cur.input! <= cur.currentValue!) {
          return acc + 1
        }

        return acc
      }, 0),
    [completedActivities]
  )

  const scoreCardItems = [
    {
      title: t(`${I18N_KEY}.activities`, 'Activities'),
      value: completedActivitiesCount,
    },
    {
      title: t(`${I18N_KEY}.aboveGoal`, 'Above Goal'),
      value: activitiesAboveGoal,
      type: ScoreCardItemType.Positive,
    },
    {
      title: t(`${I18N_KEY}.belowGoal`, 'Below Goal'),
      value: activitiesBelowGoal,
      type: ScoreCardItemType.Negative,
    },
    {
      title: t(`${I18N_KEY}.noGoal`, 'No Goal'),
      value: activitiesNoGoal,
    },
  ]

  const noneText = t(`${I18N_KEY}.none`, 'None')
  const hasActivitiesAboveGoal = activitiesAboveGoal > 0
  const hasActivitiesAboveCourse = activitiesAboveCourse > 0

  const activitiesAtGoalText = hasActivitiesAboveGoal
    ? activitiesAboveGoal
    : noneText
  const activitiesAtCourseText = hasActivitiesAboveCourse
    ? activitiesAboveCourse
    : noneText

  return (
    <>
      <HeaderSection>
        <Container>
          <Typography variant="h1">
            <Trans i18nKey={`${I18N_KEY}.title`}>Practice Summary</Trans>
          </Typography>
          <Description>
            {date &&
              format(new Date(date as Date), DateFormats.WeekDayMonthDay)}
          </Description>
        </Container>
      </HeaderSection>
      <SummaryContainer>
        <StyledFadingDotIllustration />
        <Container>
          <Typography variant="h3" gutterBottom>
            <Trans i18nKey={`${I18N_KEY}.summary`}>Summary</Trans>
          </Typography>
          <Box mb={2}>
            {completedActivitiesCount && activitiesAtGoalText && (
              <SummaryDescription>
                <Trans i18nKey={`${I18N_KEY}.comparison`}>
                  Good work, you completed{' '}
                  <strong>{{ completedActivitiesCount }}</strong> activities.{' '}
                  <Box
                    component="span"
                    color={
                      hasActivitiesAboveGoal ? 'success.main' : 'error.main'
                    }
                  >
                    <strong>{{ activitiesAtGoalText }}</strong> at or above
                  </Box>{' '}
                  your practice goal.
                </Trans>
              </SummaryDescription>
            )}
            {activitiesAtCourseText && (
              <SummaryDescription>
                <Trans i18nKey={`${I18N_KEY}.comparisonCourse`}>
                  {{ activitiesAtCourseText }} of these were at or above your
                  on-course performance from the last 5 rounds.
                </Trans>
              </SummaryDescription>
            )}
          </Box>
          <ScoreCard items={scoreCardItems} />
        </Container>
      </SummaryContainer>
      <Container>
        <Box mt={5} mb={14}>
          <Box mb={2}>
            <Typography variant="h3">{t(`${I18N_KEY}.activities`)}</Typography>
            <Typography variant="caption" component="p" color="textSecondary">
              <Trans>VS Practice Goal</Trans>
            </Typography>
          </Box>
          <PracticeSummaryTable activities={completedActivities} />
        </Box>
      </Container>
    </>
  )
}

export default Summary

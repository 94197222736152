import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import MuiPaper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
// import MuiChevronRightIcon from '@material-ui/icons/ChevronRight'

// import CommonLink from 'src/components/navigation/Link'
import SmallLogo from 'src/assets/svgComponents/SmallLogo'
import UpgradeButton from 'src/components/membership/UpgradeButton'
import { breakpoints } from 'src/utils/constants'
import { PlanType } from 'src/utils/subscriptionConstants'

const I18N_KEY = 'UpgradePanel'

const Paper = styled(MuiPaper)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: ${theme.palette.text.white};
    justify-content: center;
    padding: ${theme.spacing(6, 20, 5.25, 20)};
    background-color: ${theme.palette.background.black};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(2)}px;
    }

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 1)};
    }
  `
)

const StyledLogo = styled(SmallLogo)`
  width: 48px;
  opacity: 0.5;
`

const Title = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(2.5, 0, 1, 0)};
  `
)

const Content = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(3, 0)};
  `
)

// const Link = styled(CommonLink)(
//   ({ theme }) => css`
//     align-items: center;
//     display: flex;
//     margin: ${theme.spacing(3, 0)};
//     &:hover path {
//       fill: ${theme.palette.primary.main};
//     }
//   `
// )

// const ChevronRightIcon = styled(MuiChevronRightIcon)(
//   ({ theme }) => css`
//     transform: translateY(1px);
//     path {
//       fill: ${theme.palette.primary.light};
//     }
//   `
// )

const UpgradePanel: React.FC<{ planType: string }> = ({
  children,
  ...props
}) => {
  const { planType } = props
  let planTypeText
  switch (planType) {
    case PlanType.Train:
      // Above basic level access to features
      planTypeText = 'Train or Play'
      break
    case PlanType.Play:
      // Highest level access to features
      planTypeText = 'Play'
      break
    default:
      // When no PlanType is provided
      planTypeText = 'Paid'
  }
  // const planTypeText = planType === PlanType.Train ? 'Train or Play' : 'Play'
  return (
    <Paper {...props}>
      <StyledLogo />
      <Title variant="h4">
        <Trans i18nKey={`${I18N_KEY}.title`}>
          This is a {{ planTypeText }} feature
        </Trans>
      </Title>
      <Content variant="body2">
        {children || (
          <Trans i18nKey={`${I18N_KEY}.description`}>
            Reach your goals faster by unlocking all features available on the
            platform.
          </Trans>
        )}
      </Content>
      <UpgradeButton />
      {/* Remove till we have the website link */}
      {/* <Link to="/">
        <Trans i18nKey={`${I18N_KEY}.premiumFeaturesLinkText`}>
          View all Premium Features
        </Trans>{' '}
        <ChevronRightIcon color="primary" />
      </Link> */}
    </Paper>
  )
}

export default UpgradePanel

import React, { ChangeEvent, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Trans } from 'react-i18next'
import { Field, useFormikContext } from 'formik'

import i18n from 'i18next'
import Typography from '@material-ui/core/Typography'
import styled, { css } from 'styled-components'
import FormSelect from '../inputs/FormSelect'
import useRoundFilters from '../../hooks/useRoundFilters'
import { FormDatePicker } from '../common'
import CalendarIcon from '../../assets/svgComponents/CalendarIcon'
import {
  DateFormat,
  Languages,
  TimeFilter,
  TypeFilter,
  WhereFilter,
} from '../../utils/constants'
import { useDispatch } from 'react-redux'
import { getRoundCount } from 'src/store/roundSlice'
import { CustomTimeRange } from '../inputs/CustomDatePicker'

interface Props {
  updateRoundCount?: boolean
}

const I18N_KEY = 'InsightGeneration'

const StyledFormSelect = styled(FormSelect)`
  margin-top: 6px;
`

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)}px;
  `
)

const StyledSubGrid = styled(Grid)(
  ({ theme }) => css`
    padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  `
)

// Define an interface for form values
const RoundSelectionFilter: React.FC<Props> = ({ updateRoundCount }) => {
  const dispatch = useDispatch()
  const { typeFilterOptions, timeFilterOptions, whereFilterOptions } =
    useRoundFilters()
  const { setFieldValue, values } = useFormikContext<{
    time: TimeFilter
    type: TypeFilter
    where: WhereFilter
    customTimeRange: CustomTimeRange | null
  }>()
  const [showCustomDateRange, setShowCustomDateRange] = useState(false)

  useEffect(() => {
    dispatch(getRoundCount(values))
  }, [dispatch, values])

  const handleTimeFilterChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const time = event.target.value as TimeFilter
    setFieldValue('time', time)
    const currentValues = { ...values }
    if (updateRoundCount) {
      if (time !== TimeFilter.Custom) {
        setFieldValue('customTimeRange', null)
        currentValues.customTimeRange = null
      }
      if (time === TimeFilter.Custom && !values.customTimeRange) {
        const initCustomTimeRange = {
          startDate: new Date(),
          endDate: new Date(),
        }
        setFieldValue('customTimeRange', initCustomTimeRange)
        currentValues.customTimeRange = initCustomTimeRange
      }
      const updatedValues = {
        ...currentValues,
        time,
      }
      dispatch(getRoundCount(updatedValues))
    }
  }

  const handleTypeFilterChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const type = event.target.value as TypeFilter
    setFieldValue('type', type)
    if (updateRoundCount) {
      const updatedValues = {
        ...values,
        type,
      }
      dispatch(getRoundCount(updatedValues))
    }
  }

  const handleWhereFilterChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const where = event.target.value as WhereFilter
    setFieldValue('where', where)
    if (updateRoundCount) {
      const updatedValues = {
        ...values,
        where,
      }
      dispatch(getRoundCount(updatedValues))
    }
  }

  const handleTimeOptionChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (event.target.value === TimeFilter.Custom) {
      setShowCustomDateRange(true)
    } else {
      setShowCustomDateRange(false)
    }
    handleTimeFilterChange(event)
  }

  const handleStartTimeChange = (date: Date) => {
    setFieldValue('customTimeRange.startDate', date)
    if (updateRoundCount) {
      const updatedValues = {
        ...values,
        customTimeRange: {
          startDate: date,
          endDate: values.customTimeRange?.endDate || new Date(),
        },
      }
      dispatch(getRoundCount(updatedValues))
    }
  }

  const handleEndTimeChange = (date: Date) => {
    setFieldValue('customTimeRange.endDate', date)
    if (updateRoundCount) {
      const updatedValues = {
        ...values,
        customTimeRange: {
          startDate: values.customTimeRange?.startDate || new Date(),
          endDate: date,
        },
      }
      dispatch(getRoundCount(updatedValues))
    }
  }

  return (
    <Grid>
      <StyledGrid item xs={12} sm={6}>
        <Typography variant="caption">
          <Trans i18nKey={`${I18N_KEY}.rounds`}>Rounds</Trans>
        </Typography>
        <StyledFormSelect
          fullWidth
          options={timeFilterOptions}
          showErrorString={false}
          onChange={handleTimeOptionChange}
          name="time"
        />
      </StyledGrid>
      {showCustomDateRange ? (
        <StyledSubGrid item xs={12} sm={6}>
          <Typography variant="caption">
            <Trans i18nKey={`${I18N_KEY}.startDate`}>Start Date</Trans>
          </Typography>
          <Field
            autoOk
            fullWidth
            helperText=""
            name="customTimeRange.startDate"
            inputVariant="outlined"
            component={FormDatePicker}
            views={undefined}
            openTo={undefined}
            InputLabelProps={{ shrink: true }}
            InputProps={{ endAdornment: <CalendarIcon /> }}
            format={DateFormat[i18n.language as Languages].datePicker}
            onChange={handleStartTimeChange}
          />
          <Typography variant="caption">
            <Trans i18nKey={`${I18N_KEY}.endDate`}>End Date</Trans>
          </Typography>
          <Field
            autoOk
            fullWidth
            helperText=""
            name="customTimeRange.endDate"
            inputVariant="outlined"
            component={FormDatePicker}
            views={undefined}
            openTo={undefined}
            InputLabelProps={{ shrink: true }}
            InputProps={{ endAdornment: <CalendarIcon /> }}
            format={DateFormat[i18n.language as Languages].datePicker}
            onChange={handleEndTimeChange}
          />
        </StyledSubGrid>
      ) : (
        ''
      )}
      <StyledGrid item xs={12} sm={6}>
        <Typography variant="caption">
          <Trans i18nKey={`${I18N_KEY}.type`}>Types</Trans>
        </Typography>
        <StyledFormSelect
          fullWidth
          options={typeFilterOptions}
          showErrorString={false}
          name="type"
          onChange={handleTypeFilterChange}
        />
      </StyledGrid>
      <StyledGrid item xs={12} sm={6}>
        <Typography variant="caption">
          <Trans i18nKey={`${I18N_KEY}.where`}>Location</Trans>
        </Typography>
        <StyledFormSelect
          fullWidth
          options={whereFilterOptions}
          showErrorString={false}
          name="where"
          onChange={handleWhereFilterChange}
        />
      </StyledGrid>
    </Grid>
  )
}

export default RoundSelectionFilter

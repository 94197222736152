import React, { ChangeEvent, useEffect } from 'react'
import { useField } from 'formik'
import styled, { css, StyledProps } from 'styled-components'

import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MUIFormControl from '@material-ui/core/FormControl'
import MUISelect, { SelectProps } from '@material-ui/core/Select'
import { WhereFilter } from 'src/utils/constants'

type Props = SelectProps & ExtraProps
interface ExtraProps {
  name: string
  options: Options
  showErrorString?: boolean
  onChange?: (
    value: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void
}

interface Option {
  label: string
  value: string
  disabled?: boolean
}

export type Options = Option[]

interface ErrorProps {
  active: boolean
}

const FormControl = styled(MUIFormControl)`
  position: relative;
`

const ErrorMessage = styled(({ active, ...props }) => <span {...props} />)(
  ({ theme, active }: StyledProps<ErrorProps>) => css`
    left: 0;
    position: absolute;
    white-space: nowrap;
    opacity: ${active ? 1 : 0};
    transition: all 0.3s ease-out;
    bottom: ${active ? -23 : -10}px;
    color: ${theme.palette.error.main};
    font-size: ${theme.typography.pxToRem(12)};
  `
)

const FormSelect: React.FC<Props> = ({
  name,
  label,
  options,
  onChange,
  fullWidth,
  className,
  placeholder,
  showErrorString = true,
  ...props
}) => {
  const [field, meta, helpers] = useField({
    name,
    multiple: true,
    component: 'select',
  })

  const { value, onChange: onFieldChange } = field
  const hasError = meta.touched && !!meta.error

  useEffect(() => {
    // Set initial value for 'where' field if not set
    if (field.name === 'where' && !value) {
      helpers.setValue(WhereFilter.Outdoor)
    }
  }, [field.name, value, helpers])

  // Allow ability to add custom onChange
  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    if (onChange) {
      onChange(e)
    } else {
      onFieldChange(e)
    }
  }

  return (
    <FormControl className={className} fullWidth={fullWidth} error={hasError}>
      {label && (
        <InputLabel shrink={placeholder ? true : undefined}>{label}</InputLabel>
      )}
      <MUISelect
        {...field}
        {...props}
        variant="outlined"
        value={value || ''}
        onChange={handleChange}
        IconComponent={ExpandMore}
        MenuProps={{
          elevation: 1,
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'top', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
        }}
      >
        {options.map(({ label, value, disabled }) => (
          <MenuItem
            key={value}
            value={value}
            disabled={disabled}
            data-cy="select-option"
          >
            {label}
          </MenuItem>
        ))}
      </MUISelect>
      {showErrorString && (
        <ErrorMessage active={hasError}>{hasError && meta.error}</ErrorMessage>
      )}
    </FormControl>
  )
}

export default React.memo(FormSelect)

import React, { useMemo, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, FormikConfig, FormikProps } from 'formik'

import {
  playerSelector,
  updatePlayerProfile,
  UpdatePlayerRequest,
} from 'src/store/playerSlice'
import { Content } from 'src/modules/player-settings/common'
import { createProfileSchema } from 'src/utils/validationSchemas'
import ProfileForm from 'src/modules/player-settings/profile/ProfileForm'
import { openToast, getSuccessToast, getErrorToast } from 'src/store/toastSlice'
import { getSummary } from 'src/store/summarySlice'

const I18N_KEY = 'SettingsProfile'

const Profile: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const player = useSelector(playerSelector)

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  const validationSchema = useMemo(() => createProfileSchema(t), [t])

  const handleSubmit = async (player: UpdatePlayerRequest) => {
    try {
      const result = await dispatch(updatePlayerProfile(player))
      dispatch(
        openToast(
          getSuccessToast(
            t(`${I18N_KEY}.successToast`, 'Profile successfully updated')
          )
        )
      )
      // Fetch summary here to refresh the players view after changing their unit
      dispatch(getSummary())
      return result
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(
            t(
              `${I18N_KEY}.errorToast`,
              'Error updating your profile. Please try again later'
            )
          )
        )
      )
      return player
    }
  }

  const formikOptions: FormikConfig<UpdatePlayerRequest> = {
    initialValues: {
      dob: player.dob,
      unit: player.unit,
      gender: player.gender,
      lastName: player.lastName,
      firstName: player.firstName,
      playerType: player.playerType,
    },
    validationSchema,
    onSubmit: values => handleSubmit(values),
  }

  const handleChange: (formik: FormikProps<UpdatePlayerRequest>) => void = (
    formik
  ): void => {
    setHasUnsavedChanges(formik.dirty)
  }

  const unsavedChangesMessage = useMemo(
    () =>
      t(
        `${I18N_KEY}.unsavedChangesLeaveConfirm`,
        'You have unsaved changes. Are you sure you want to leave?'
      ) as string,
    [t]
  )

  return (
    <>
      <Content>
        <Formik {...formikOptions}>
          <ProfileForm
            player={player}
            onChange={handleChange}
            profileImageUrl={player.profileImageUrl}
          />
        </Formik>
      </Content>
      <Prompt when={hasUnsavedChanges} message={unsavedChangesMessage} />
    </>
  )
}

export default Profile

import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Control, Controller } from 'react-hook-form'
import CalendarIcon from 'src/assets/svgComponents/CalendarIcon'

import { Typography } from '@material-ui/core'
import { DatePicker, DatePickerProps } from '@material-ui/pickers'

import { DateFormat, Languages } from 'src/utils/constants'

interface HookDatePickerProps {
  name: string
  label?: string
  control: Control
  defaultDate?: Date
  datePickerProps?: Omit<DatePickerProps, 'value' | 'onChange'>
}

const StyledPicker = styled(DatePicker)(
  ({ theme }) => css`
    margin: ${theme.spacing(0.5, 0, 0)};
  `
)

const today = new Date()

const HookDatePicker: React.FC<HookDatePickerProps> = ({
  name,
  label,
  control,
  defaultDate,
  datePickerProps,
}) => {
  const { i18n } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultDate || today}
      render={props => (
        <>
          {label && <Typography variant="caption">{label}</Typography>}
          <StyledPicker
            autoOk
            fullWidth
            helperText=""
            disableFuture
            inputVariant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{ endAdornment: <CalendarIcon /> }}
            format={DateFormat[i18n.language as Languages].datePicker}
            {...props}
            {...datePickerProps}
            data-cy="date-picker"
          />
        </>
      )}
    />
  )
}

export default HookDatePicker

import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Box, Typography, TypographyProps } from '@material-ui/core'

import { handleNumberFormat } from 'src/utils/helpers'
import { DECIMAL_PLACES, NO_PROGRESS_LABEL } from 'src/utils/constants'
import NoData from './NoData'

const I18N_KEY = 'GraphProgressLabel'

interface Props {
  dataExists: boolean
  noDataText?: string
  sgValue: null | number | undefined
  progress: null | number | undefined
  noDataTypographyProps?: TypographyProps
  strokesTypographyProps?: TypographyProps
  progressTypographyProps?: TypographyProps
}

interface TextProps {
  $progress: number
}

const SGText = styled(Typography)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(20)};
      line-height: ${theme.typography.pxToRem(24)};
    }
  `
)

const ProgressText = styled(Typography)<TextProps>(
  ({ theme, $progress }) => css`
    font-weight: bold;
    color: ${$progress > 0
      ? theme.palette.success.main
      : $progress < 0
      ? theme.palette.error.main
      : theme.palette.background.grey};
    padding: ${theme.spacing(0.5, 0, 0, 1)};

    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `
)

const GraphProgressLabel: React.FC<Props> = ({
  sgValue,
  progress,
  dataExists,
  noDataText,
  strokesTypographyProps = { variant: 'h3' },
  progressTypographyProps = { variant: 'h5' },
  noDataTypographyProps = { variant: 'body1' },
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Box display="flex" mb={1} {...props}>
      {dataExists ? (
        sgValue ? (
          <SGText {...strokesTypographyProps}>
            {sgValue?.toFixed(DECIMAL_PLACES)}
          </SGText>
        ) : (
          <NoData />
        )
      ) : (
        <Typography {...noDataTypographyProps}>
          {noDataText ||
            t(
              `${I18N_KEY}.noShotsInThisTimePeriod`,
              'No shots in this time period'
            )}
        </Typography>
      )}
      {dataExists && (
        <ProgressText {...progressTypographyProps} $progress={progress!}>
          {progress
            ? progress === 0
              ? NO_PROGRESS_LABEL
              : `${
                  progress! > 0 ? '▲' : progress! < 0 ? '▼' : ''
                }${handleNumberFormat(progress as number, true)}`
            : null}
        </ProgressText>
      )}
    </Box>
  )
}

export default GraphProgressLabel

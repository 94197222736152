import React, { useState, useContext, MouseEvent } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

import { DateFormats } from 'src/utils/constants'
import { Practice } from 'src/utils/golfConstants'
import { IconButton } from 'src/modules/activity/common'
import { DataRow } from 'src/components/dataDisplay/tableStyles'
import { TranslationContext } from 'src/utils/TranslationContext'

import { ReactComponent as CalendarCheckIcon } from 'src/assets/images/CalendarCheck.svg'

const I18N_KEY = 'PracticeRow'

const StyledDataRow = styled(DataRow)(
  ({ theme }) => css`
    .MuiTableCell-root {
      padding-right: ${theme.spacing(1)}px;
    }
  `
)

const IconContainer = styled.div(
  ({ theme }) => css`
    width: 35px;
    height: 35px;
    display: flex;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: ${theme.palette.primary.light};
  `
)

const CellContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

interface Props {
  practice: Practice
  handleDeleteClick: (e: MouseEvent<HTMLLIElement>) => void
  handlePracticeClick: (e: MouseEvent<HTMLTableRowElement>) => void
}

const PracticeRow: React.FC<Props> = ({
  practice,
  handleDeleteClick,
  handlePracticeClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { format } = useContext(TranslationContext)!

  const { uuid, date } = practice
  const formattedDate = format(new Date(date), DateFormats.TableRow)

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <StyledDataRow id={uuid} key={uuid} onClick={handlePracticeClick}>
        <TableCell>
          <CellContent>
            <Box display="flex" alignItems="center">
              <IconContainer>
                <CalendarCheckIcon />
              </IconContainer>
              <Box ml={3}>
                <Typography variant="body2">
                  <Trans i18nKey={`${I18N_KEY}.title`}>Practice Session</Trans>
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {formattedDate}
                </Typography>
              </Box>
            </Box>
            <IconButton
              size="small"
              color="primary"
              onClick={openMenu}
              aria-label="Options"
              data-cy="open-practice-options"
            >
              <MoreIcon />
            </IconButton>
          </CellContent>
        </TableCell>
      </StyledDataRow>
      <Menu
        elevation={1}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={e => {
            handleDeleteClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.delete`}>Delete</Trans>
        </MenuItem>
      </Menu>
    </>
  )
}

export default React.memo(PracticeRow)

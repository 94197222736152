import React, { useContext, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box, useTheme } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import {
  Area,
  XAxis,
  YAxis,
  Scatter,
  ComposedChart,
  ResponsiveContainer,
  ReferenceLine,
  CartesianGrid,
} from 'recharts'
import { RenderXAxisTick, RenderYAxisTick } from 'src/components/graph/Axis'
import { renderScatterDot } from 'src/components/graph/composed/ScatterDot'
import { getXAxisTicks } from 'src/components/graph/utils'
import { PracticeResult } from 'src/models/practice-summary'
import { fillMixedGraphPoints } from '../util/helper'
import { SummaryGraphPoint } from 'src/store/summarySlice'
import PerformanceToGoalLabel from 'src/components/dataDisplay/PerformanceToGoalLabel'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { unitSelector } from 'src/store/playerSlice'
import { LanguageContext } from 'src/utils/LanguageProvider'
import { Unit } from 'src/utils/constants'
import { convertYardsToMetersForLabels } from 'src/utils/helpers'

// const I18N_KEY = 'PracticeResultGraph'

interface Props {
  payload: PracticeResult
}

const Container = styled(Paper)(
  ({ theme }) => css`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.spacing(3, 3, 3, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 2, 0, 0)};
    }
  `
)

const HeaderContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${theme.breakpoints.up(750)} {
      flex-direction: row;
      justify-content: space-between;
    }
  `
)

const HeaderInfo = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${theme.spacing(4.5)}px;

    ${theme.breakpoints.down('xs')} {
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      margin: ${theme.spacing(0, 0, 1.5)};
      padding-left: ${theme.spacing(2)}px;
      width: '100%';
    }
  `
)

const PracticeResultGraph: React.FC<Props> = ({ payload }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [isActive, setIsActive] = useState(false)
  const { language } = useContext(LanguageContext)
  const unit = useSelector(unitSelector)

  const handleActive = () => {
    setIsActive(true)
  }

  const handleInActive = () => {
    setIsActive(false)
  }

  const averageInput =
    payload.activities.reduce((sum, activity) => sum + activity.input, 0) /
    payload.activities.length

  const data = useMemo(() => {
    let graphDataPoints: SummaryGraphPoint[] = []
    const practiceGraphPoints = payload.activities.map(
      activity =>
        ({
          datePlayed: new Date(activity.datePlayed).getTime(),
          value: activity.input,
          rollingAverage: null,
          practiceRollingAverage: activity.activityRollingAverage,
          roundType: 'Practice',
        } as SummaryGraphPoint)
    )

    if (practiceGraphPoints.length === 2) {
      if (
        practiceGraphPoints[0].practiceRollingAverage &&
        practiceGraphPoints[1].practiceRollingAverage
      ) {
        practiceGraphPoints[0].practiceRollingAverage =
          practiceGraphPoints[1].practiceRollingAverage
      }
    }

    const roundGraphPoints = payload.rounds
      .filter(round => round.metricSummary.value !== null)
      .map(
        round =>
          ({
            datePlayed: new Date(round.datePlayed).getTime(),
            value: round.metricSummary.value,
            rollingAverage: round.metricSummary.rollingAverage,
            practiceRollingAverage: null,
            roundType: 'Course',
          } as SummaryGraphPoint)
      )

    if (roundGraphPoints.length === 2) {
      if (
        roundGraphPoints[0].rollingAverage &&
        roundGraphPoints[1].rollingAverage
      ) {
        roundGraphPoints[0].rollingAverage = roundGraphPoints[1].rollingAverage
      }
    }

    graphDataPoints = fillMixedGraphPoints(
      roundGraphPoints,
      practiceGraphPoints
    )

    return graphDataPoints
  }, [payload.activities, payload.rounds])

  const xTicks = useMemo(
    () => getXAxisTicks(data?.map(({ datePlayed }) => datePlayed) || []),
    [data]
  )

  return (
    <Container
      elevation={0}
      onMouseEnter={handleActive}
      onMouseLeave={handleInActive}
    >
      <HeaderContent>
        <HeaderInfo>
          <Typography>
            {unit === Unit.Metric
              ? convertYardsToMetersForLabels(
                  t(`Enums:Activity.${payload.activityId}`),
                  language
                )
              : t(`Enums:Activity.${payload.activityId}`)}
          </Typography>
          <PerformanceToGoalLabel
            dataExists={data.length > 0}
            value={averageInput}
            unit={payload.activities[0].unit}
            averagePercent={payload.averagePerformanceToGoal}
          />
        </HeaderInfo>
      </HeaderContent>
      <Box>
        <ResponsiveContainer height={235}>
          <ComposedChart width={730} height={235} data={data}>
            {isActive && (
              <CartesianGrid
                vertical={false}
                stroke={theme.palette.background.light}
              />
            )}
            <XAxis
              dataKey="datePlayed"
              tickLine={false}
              axisLine={false}
              ticks={xTicks}
              tickFormatter={() => 'XXX 99'}
              tick={props => <RenderXAxisTick {...props} />}
              tickMargin={10}
              domain={['dataMin', 'dataMax']}
            />
            <YAxis
              type="number"
              tickLine={false}
              axisLine={false}
              tick={props => <RenderYAxisTick {...props} />}
              domain={[
                (dataMin: number) => dataMin * 0.8,
                (dataMax: number) => dataMax * 1.2,
              ]}
            />
            <ReferenceLine
              y={averageInput}
              strokeWidth={2}
              strokeDasharray="4"
              ifOverflow="extendDomain"
              stroke={theme.palette.warning.main}
            />
            {payload.activities.length > 1 && (
              <Area
                dot={false}
                type="monotone"
                strokeWidth={2}
                fillOpacity={1}
                dataKey="practiceRollingAverage"
                activeDot={false}
                animationDuration={1000}
                stroke="#121212"
                fill="transparent"
                connectNulls
              />
            )}
            <Area
              dot={false}
              type="monotone"
              strokeWidth={2}
              fillOpacity={1}
              dataKey="rollingAverage"
              activeDot={false}
              animationDuration={1000}
              stroke={theme.palette.primary.light}
              fill="transparent"
              connectNulls
            />
            <Scatter
              dataKey="value"
              shape={renderScatterDot}
              animationDuration={1000}
              fill={theme.palette.primary.light}
              stroke={theme.palette.primary.light}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Container>
  )
}

export default PracticeResultGraph

import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, useLocation } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Box, Typography } from '@material-ui/core'

import {
  Container,
  Form,
  StyledLink,
  TitleContainer,
} from 'src/modules/auth/common'
import { api } from 'src/utils/api'
import useLogin from 'src/hooks/useLogin'
import { login, updateRequiresImmediatePayment } from 'src/store/authSlice'
import { hashPassword } from 'src/utils/crypto'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import HookDatePicker from 'src/components/inputs/HookDatePicker'
import { createRegisterSchema } from 'src/utils/validationSchemas'
import HookToggleButton from 'src/components/inputs/HookToggleButton'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import HookPasswordField from 'src/components/inputs/HookPasswordField'
import {
  Gender,
  PlayerType,
  Routes,
  Unit,
  UserType,
  isChina,
} from 'src/utils/constants'
import styled from 'styled-components'
import { Label } from '../../components/Label'
import { HookFormPhoneField } from '../../components/inputs/HookFormPhoneField'
import { trackEvent } from 'src/utils/analytics'
import { getPlanByName } from 'src/store/planSlice'

const I18N_KEY = 'Register'

interface FormValues {
  email: string
  dialCode: string
  phoneNumber: string
  unit: string
  gender: string
  password: string
  userType: string
  lastName: string
  firstName: string
  playerType: string
  recaptchaToken: string
  confirmPassword: string
  rewardfulReferralToken: string
  referral: string
  inviteUUID: string
}

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
`
const Register: React.FC<{ requiresPayment?: boolean }> = ({
  requiresPayment = false,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoggedIn } = useLogin()
  const { t, i18n } = useTranslation()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [inviteUUID, setInviteUUID] = useState('')
  const [referral, setReferral] = useState('')
  const [rewardfulReferralToken, setRewardfulReferralToken] = useState('')

  const { language } = i18n
  const { executeRecaptcha } = useGoogleReCaptcha()

  const validationSchema = useMemo(() => createRegisterSchema(t), [t])

  const userTypeOptions = [
    { label: t(`Enums:UserType.${UserType.Player}`), value: UserType.Player },
    {
      label: t(`Enums:UserType.${UserType.Coach}`),
      value: UserType.Coach,
    },
  ]

  const genderOptions = [
    { label: t(`Enums:Gender.${Gender.Male}`), value: Gender.Male },
    {
      label: t(`Enums:Gender.${Gender.Female}`),
      value: Gender.Female,
    },
  ]

  const unitOptions = [
    { label: t(`Enums:Unit.${Unit.Metric}`), value: Unit.Metric },
    {
      label: t(`Enums:Unit.${Unit.Imperial}`),
      value: Unit.Imperial,
    },
  ]

  const playerTypeOptions = [
    {
      label: t(`Enums:PlayerType.${PlayerType.Amateur}`),
      value: PlayerType.Amateur,
    },
    { label: t(`Enums:PlayerType.${PlayerType.Pro}`), value: PlayerType.Pro },
  ]
  const { errors, control, formState, handleSubmit } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      dialCode: isChina ? '+86' : '+64',
      phoneNumber: '',
      password: '',
      lastName: '',
      firstName: '',
      unit: Unit.Metric,
      recaptchaToken: '',
      rewardfulReferralToken: '',
      confirmPassword: '',
      gender: Gender.Male,
      userType: UserType.Player,
      playerType: PlayerType.Amateur,
    },
  })

  const { isDirty, isValid, isSubmitting } = formState

  const onSubmit = handleSubmit(
    async ({ password, confirmPassword, ...values }) => {
      try {
        try {
          if (!executeRecaptcha) return
          values.recaptchaToken = await executeRecaptcha('register')
        } catch (error) {
          values.recaptchaToken = ''
        }

        values.rewardfulReferralToken = rewardfulReferralToken

        if (inviteUUID.length !== 0) {
          values.inviteUUID = inviteUUID
        }

        if (referral.length !== 0) {
          values.referral = referral
        }

        const hubspotUserToken =
          document.cookie.replace(
            /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
            '$1'
          ) || ''

        const phoneNumber = values.dialCode + values.phoneNumber
        try {
          await api.post('user/register', {
            language,
            password: hashPassword(password),
            ...values,
            dialCode: undefined,
            phoneNumber,
            hubspotUserToken,
          })
        } catch (registerError: any) {
          if (registerError.response?.status === 409) {
            throw registerError
          }
        }

        trackEvent('Signed Up', {
          email: values.email,
          username: values.email,
          last_name: values.lastName,
          user_type: values.userType,
          first_name: values.firstName,
          phone_number: phoneNumber,
          type: inviteUUID ? 'invited' : 'organic',
        })

        if (requiresPayment) {
          await dispatch(
            updateRequiresImmediatePayment({ requiresImmediatePayment: true })
          )
        }
        await dispatch(login({ password, email: values.email }, false))
      } catch (error: any) {
        if (error.response?.status === 409) {
          dispatch(
            openToast(
              getErrorToast(
                t(
                  `${I18N_KEY}.registrationConflictError`,
                  'Email already associated with a Circles account. Please try log in.'
                )
              )
            )
          )
        } else {
          dispatch(
            openToast(
              getErrorToast(
                t(
                  `${I18N_KEY}.apiRegistrationError`,
                  'Something went wrong. Please try again'
                )
              )
            )
          )
        }
      }
    }
  )

  useEffect(() => {
    if (isChina) {
      // This plan is only relevant to China
      dispatch(getPlanByName('CLPGA One Month'))
    }
  }, [dispatch])

  useEffect(() => {
    ;(window as any).rewardful('ready', () => {
      const rewardfulReferralToken = (window as any).Rewardful.referral
      if (rewardfulReferralToken) {
        setRewardfulReferralToken(rewardfulReferralToken)
      }
    })

    // Hubspot client integration
    // eslint-disable-next-line
    var _hsq = ((window as any)._hsq = (window as any)._hsq || [])
    _hsq.push(['setPath', '/register'])
    _hsq.push(['trackPageView'])
  }, [])

  // Once user is registered, navigate them to their dashboard
  useEffect(() => {
    if (isLoggedIn) {
      history.replace(Routes.Dashboard)
    }
  }, [history, isLoggedIn])

  useEffect(() => {
    const inviteParam = searchParams.get('inviteUUID')
    if (inviteParam) {
      setInviteUUID(inviteParam)
    }
    const referralParam = searchParams.get('referral')
    if (referralParam) {
      setReferral(referralParam)
    }
  }, [searchParams])

  return (
    <Container {...motionProps}>
      <TitleContainer>
        <Typography variant="h1" align="center">
          <Trans i18nKey={`${I18N_KEY}.title`}>Create account</Trans>
        </Typography>
        {!requiresPayment && (
          <Box mt={1} component="span" display="flex" justifyContent="center">
            <Typography color="textSecondary">
              <Trans i18nKey={`${I18N_KEY}.hasAccount`}>
                Already have an account?
              </Trans>
            </Typography>
            <StyledLink to={Routes.SignIn}>
              <Trans i18nKey={`${I18N_KEY}.signIn`}>Sign in</Trans>
            </StyledLink>
          </Box>
        )}
      </TitleContainer>
      <Form onSubmit={onSubmit} data-rewardful>
        <Box mb={4}>
          <HookFormTextField
            name="email"
            type="email"
            errors={errors}
            control={control}
            label={t(`${I18N_KEY}.emailLabel`, 'Email')}
            placeholder={t(`${I18N_KEY}.emailPlaceholder`, 'Your email')}
          />
        </Box>
        <Label $hasError={false}>Mobile</Label>
        <StyledBox mb={4}>
          <HookFormPhoneField
            errors={errors}
            control={control}
            placeholder={t(
              `${I18N_KEY}.phoneNumberPlaceholder`,
              'Your phone number'
            )}
          />
        </StyledBox>
        <Box mb={4}>
          <HookPasswordField
            name="password"
            errors={errors}
            control={control}
            label={t(`${I18N_KEY}.passwordLabel`, 'Password')}
            placeholder={t(`${I18N_KEY}.passwordPlaceholder`, '8+ characters')}
          />
        </Box>
        <Box mb={4}>
          <HookPasswordField
            errors={errors}
            control={control}
            name="confirmPassword"
            label={t(`${I18N_KEY}.confirmPasswordLabel`, 'Confirm password')}
            placeholder={t(`${I18N_KEY}.passwordPlaceholder`, '8+ characters')}
          />
        </Box>
        <Box mb={4}>
          <HookToggleButton
            name="userType"
            control={control}
            options={userTypeOptions}
            label={t(`${I18N_KEY}.userType`, 'Sign up as')}
          />
        </Box>
        <Box mb={4}>
          <HookToggleButton
            name="gender"
            control={control}
            options={genderOptions}
            label={t(`${I18N_KEY}.gender`, 'Gender')}
          />
        </Box>
        <Box mb={4}>
          <HookFormTextField
            name="firstName"
            errors={errors}
            control={control}
            label={t(`${I18N_KEY}.firstName`, 'First name')}
            placeholder={t(`${I18N_KEY}.firstName`, 'First name')}
          />
        </Box>
        <Box mb={4}>
          <HookFormTextField
            name="lastName"
            errors={errors}
            control={control}
            label={t(`${I18N_KEY}.lastName`, 'Last name')}
            placeholder={t(`${I18N_KEY}.lastName`, 'Last name')}
          />
        </Box>
        <Box mb={4}>
          <HookDatePicker
            name="dob"
            control={control}
            label={t(`${I18N_KEY}.dob`, 'Date of birth')}
            datePickerProps={{
              openTo: 'year',
              views: ['year', 'month', 'date'],
            }}
          />
        </Box>
        <Box mb={4}>
          <HookToggleButton
            name="unit"
            control={control}
            options={unitOptions}
            label={t(`${I18N_KEY}.units`, 'Scoring units')}
          />
        </Box>
        <Box mb={4}>
          <HookToggleButton
            name="playerType"
            control={control}
            options={playerTypeOptions}
            label={t(`${I18N_KEY}.playerType`, 'Player type')}
          />
        </Box>
        <LoaderButton
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          loading={isSubmitting}
          disabled={!isDirty || !isValid}
        >
          {!requiresPayment
            ? t(`${I18N_KEY}.buttonLabel`, 'Create account')
            : t(`${I18N_KEY}.buttonCreateAndPay`, 'Create and Pay')}
        </LoaderButton>
      </Form>
    </Container>
  )
}

export default Register

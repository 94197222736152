import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import TableCell from '@material-ui/core/TableCell'
import { breakpoints, Languages, Unit } from '../../../utils/constants'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TFunction, Trans, useTranslation } from 'react-i18next'
import TableBody from '@material-ui/core/TableBody'
import {
  convertYardsToMetersForLabels,
  handleNumberFormat,
} from '../../../utils/helpers'
import Box from '@material-ui/core/Box'
import {
  ShotsDistributionItem,
  TimeSpentItem,
} from '../../../utils/golfConstants'
import { DashIcon } from '../../../components/graph/common'
import NoData from 'src/components/dataDisplay/NoData'
import { LanguageContext } from 'src/utils/LanguageProvider'
import { useSelector } from 'react-redux'
import { unitSelector } from 'src/store/playerSlice'

interface Props {
  data: ShotsDistributionItem[] | TimeSpentItem[]
  isCompactSummary: boolean
  isPracticeDistribution?: boolean
}

const StyledCell = styled(TableCell)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.5, 1.5)};
    text-align: center;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: 0;
      text-align: center;
      min-width: unset;
      max-width: 100px;
    }
  `
)

const StyledTitleCell = styled(TableCell)(
  ({ theme }) => css`
    &:first-child {
      text-align: left;
    }
    &:not(:first-child) {
      text-align: center;
    }
    padding-left: 0;
    padding-right: 0;
    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: 0;
      min-width: unset;
      max-width: 100px;
      padding-bottom: ${theme.spacing(1)}px;
    }
  `
)

const I18N_KEY = 'ShotDistribution'

const createShotDistributionTableBody = (
  data: Partial<ShotsDistributionItem>[],
  t: TFunction,
  language: Languages,
  unit: Unit | null
) => {
  return data.map(value => (
    <TableRow key={value.name}>
      <StyledTitleCell>
        {unit === Unit.Metric
          ? convertYardsToMetersForLabels(
              t(`${I18N_KEY}.${value.name}`, 'label'),
              language
            )
          : t(`${I18N_KEY}.${value.name}`, 'label')}
      </StyledTitleCell>
      <StyledCell>
        <Typography variant="body2">
          {value.percentage ? (
            handleNumberFormat(value.percentage * 100, false, 1)
          ) : (
            <DashIcon />
          )}
          %
        </Typography>
      </StyledCell>

      <StyledCell>
        <Typography variant="body2">
          {value.averagePerRound ? (
            handleNumberFormat(value.averagePerRound)
          ) : (
            <DashIcon />
          )}
        </Typography>
      </StyledCell>
      <StyledCell>
        <Typography variant="body2">
          <Box
            component="span"
            color={
              value.perShot
                ? value.perShot < 0
                  ? 'error.main'
                  : 'success.main'
                : ''
            }
          >
            {value.perShot ? handleNumberFormat(value.perShot) : <DashIcon />}
          </Box>
        </Typography>
      </StyledCell>
    </TableRow>
  ))
}

const createPracticeDistributionTableBody = (
  data: Partial<TimeSpentItem>[]
) => {
  return data.map(value => (
    <TableRow key={value.name}>
      <StyledTitleCell>
        <Typography>
          <Trans i18nKey={`${I18N_KEY}.${value.name}`}>{value.name}</Trans>
        </Typography>
      </StyledTitleCell>
      <StyledCell>
        <Typography variant="body2">
          <Box
            component="span"
            color={
              value.strokesGained
                ? value.strokesGained < 0
                  ? 'error.main'
                  : 'success.main'
                : ''
            }
          >
            {value.strokesGained ? (
              handleNumberFormat(value.strokesGained)
            ) : (
              <DashIcon />
            )}
          </Box>
        </Typography>
      </StyledCell>

      <StyledCell>
        <Typography variant="body2">
          {value.percentage
            ? handleNumberFormat(value.percentage * 100, false, 1)
            : 0}
          %
        </Typography>
      </StyledCell>

      <StyledCell>
        <Typography variant="body2">
          {value.averagePerMonth ? (
            handleNumberFormat(value.averagePerMonth, false, 1)
          ) : (
            <DashIcon />
          )}
        </Typography>
      </StyledCell>
      <StyledCell>
        <Typography variant="body2">
          <Box
            component="span"
            color={
              value.averagePercentageToGoal
                ? value.averagePercentageToGoal * 100 < 100
                  ? 'error.main'
                  : 'success.main'
                : ''
            }
          >
            {value.averagePercentageToGoal ? (
              `${handleNumberFormat(
                value.averagePercentageToGoal * 100,
                false,
                0
              )}%`
            ) : (
              <NoData />
            )}
          </Box>
        </Typography>
      </StyledCell>
    </TableRow>
  ))
}

const ShotsDistributionTable: React.FC<Props> = ({
  data,
  isCompactSummary,
  isPracticeDistribution,
  ...props
}) => {
  const { t } = useTranslation()
  const { language } = useContext(LanguageContext)
  const unit = useSelector(unitSelector)

  return (
    <TableContainer {...props}>
      <Table aria-label="rounds table">
        <TableHead>
          <TableRow>
            <StyledTitleCell>
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.category`}>Category</Trans>
              </Typography>
            </StyledTitleCell>
            {isPracticeDistribution ? (
              <>
                <StyledTitleCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.sg`}>SG</Trans>
                  </Typography>
                </StyledTitleCell>
                <StyledTitleCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.percentOfTime`}>
                      % of time
                    </Trans>
                  </Typography>
                </StyledTitleCell>
                <StyledTitleCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.perMonth`}>
                      Avg # per month
                    </Trans>
                  </Typography>
                </StyledTitleCell>
                <StyledTitleCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.avgToGoal`}>Avg to goal</Trans>
                  </Typography>
                </StyledTitleCell>
              </>
            ) : (
              <>
                {!isCompactSummary && (
                  <>
                    <StyledTitleCell>
                      <Typography variant="caption" color="textSecondary">
                        <Trans i18nKey={`${I18N_KEY}.percentOfTime`}>
                          % of time
                        </Trans>
                      </Typography>
                    </StyledTitleCell>
                    <StyledTitleCell>
                      <Typography variant="caption" color="textSecondary">
                        <Trans i18nKey={`${I18N_KEY}.averagePerRound`}>
                          Avg # per round
                        </Trans>
                      </Typography>
                    </StyledTitleCell>
                  </>
                )}
                <StyledTitleCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.sgPerShot`}>SG per shot</Trans>
                  </Typography>
                </StyledTitleCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isPracticeDistribution
            ? createPracticeDistributionTableBody(data)
            : createShotDistributionTableBody(data, t, language, unit)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ShotsDistributionTable

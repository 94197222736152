import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  authRequestFail,
  getPayloadFromResponse,
  getRoundTitle,
  getSinglePayloadFromResponse,
  getUserInfo,
  isForbiddenOrUnauthorised,
} from 'src/utils/helpers'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { CategorisedActivities } from 'src/utils/practiceActivities'
import { RootState } from 'src/store/rootReducer'
import {
  MembershipLevel,
  PAGINATION_LIMIT,
  PracticeTimeFilter,
} from 'src/utils/constants'
import {
  Activity,
  CategoryType,
  MetricId,
  PARENT_CATEGORY_TYPE_MAP,
  Practice,
  PracticeTimeSpent,
} from 'src/utils/golfConstants'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import { extractComposedStrokesData, SubcategoryData } from './summarySlice'
import { PracticeSummary } from 'src/models/practice-summary'

interface InitialState {
  isOpen: boolean
  totalCount: number
  totalSubmitted: number
  practices: Practice[]
  practicesLoaded: boolean
  status: PracticeStatus
  selectedPractice: Practice | null
  isSelectedPracticeLoading: boolean
  practiceSummary: PracticeSummary
  isPracticeSummaryLoading: boolean
  timeSpent: PracticeTimeSpent | null
  timeSpentLoading: boolean
  filters: Filters
}

export enum PracticeStatus {
  Valid = 'valid',
  Error = 'error',
  Loading = 'loading',
  CreatingNew = 'creating_new',
  NoPracticesError = 'no_practices_error',
}

export type ActivityPayload = Pick<
  Activity,
  'input' | 'uuid' | 'definition' | 'activityId' | 'activityName'
>

interface DialogVisibility {
  isOpen: boolean
  uuid?: string
}

export interface Filters {
  timeFilter: PracticeTimeFilter
  customTimeRange: string | null
}

interface PracticeParams {
  [key: string]: any
}

const initialState: InitialState = {
  practices: [],
  practicesLoaded: false,
  isOpen: false,
  totalCount: 0,
  totalSubmitted: 0,
  selectedPractice: null,
  status: PracticeStatus.Valid,
  isSelectedPracticeLoading: false,
  practiceSummary: {
    rounds: [],
    summary: {
      categories: [],
      strokesGained: {
        putt: {
          progress: 0,
          benchmark: 0,
          overall: null,
        },
        short: {
          progress: 0,
          benchmark: 0,
          overall: null,
        },
        total: {
          progress: 0,
          benchmark: 0,
          overall: null,
        },
        drives: {
          progress: 0,
          benchmark: 0,
          overall: null,
        },
        approach: {
          progress: 0,
          benchmark: 0,
          overall: null,
        },
      },
    },
    practiceResults: [],
  },
  isPracticeSummaryLoading: false,
  timeSpent: null,
  timeSpentLoading: false,
  filters: {
    timeFilter: PracticeTimeFilter.LastMonth,
    customTimeRange: null,
  },
}

const { actions, reducer } = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    updateStatus: (
      state,
      action: PayloadAction<Pick<InitialState, 'status'>>
    ) => {
      const { status } = action.payload

      state.status = status

      if (
        status === PracticeStatus.Error ||
        status === PracticeStatus.NoPracticesError
      ) {
        state.practices = []
      }
    },
    updateLoaded: (state, action: PayloadAction<{ loaded: boolean }>) => {
      state.practicesLoaded = action.payload.loaded
    },
    updateDialogVisibility: (
      state,
      action: PayloadAction<DialogVisibility>
    ) => {
      const { isOpen, uuid } = action.payload

      const selectedPractice = state.practices?.find(item => item.uuid === uuid)

      state.selectedPractice = selectedPractice || null
      state.isOpen = isOpen
    },
    setSelectedPracticeByUuid: (
      state,
      action: PayloadAction<{ uuid: string }>
    ) => {
      const selectedPractice = state.practices?.find(
        item => item.uuid === action.payload.uuid
      )

      state.selectedPractice = selectedPractice || null
    },
    setSelectedPractice: (
      state,
      action: PayloadAction<{ practice: Practice }>
    ) => {
      state.selectedPractice = action.payload.practice
    },
    updateSelectedPracticeLoading: (
      state,
      action: PayloadAction<{ isLoading: boolean }>
    ) => {
      const { isLoading } = action.payload

      state.isSelectedPracticeLoading = isLoading
    },
    updatePracticeList: (
      state,
      action: PayloadAction<
        Pick<InitialState, 'practices' | 'totalCount' | 'totalSubmitted'>
      >
    ) => {
      const { practices, totalCount, totalSubmitted } = action.payload

      state.practices = practices
      state.totalCount = totalCount
      state.totalSubmitted = totalSubmitted
      state.status = PracticeStatus.Valid
    },
    updatePractice: (state, action: PayloadAction<{ practice: Practice }>) => {
      const { practice } = action.payload

      const updatedPractices = state.practices.slice()
      const practiceIndex = updatedPractices.findIndex(
        item => item.uuid === practice.uuid
      )

      if (practiceIndex < 0) {
        updatedPractices.push(practice)
        state.totalCount = state.totalCount + 1
      } else {
        updatedPractices[practiceIndex] = practice
      }

      state.selectedPractice = practice
      state.practices = updatedPractices
      state.isSelectedPracticeLoading = false
    },
    updatePracticeSummary: (
      state,
      action: PayloadAction<{ practiceSummary: PracticeSummary }>
    ) => {
      const { practiceSummary } = action.payload
      state.practiceSummary = practiceSummary
      state.isPracticeSummaryLoading = false
    },
    updatePracticeSummaryLoading: (
      state,
      action: PayloadAction<{ loading: boolean }>
    ) => {
      const { loading } = action.payload
      state.isPracticeSummaryLoading = loading
    },
    updateTimeSpent: (
      state,
      action: PayloadAction<{ timeSpent: PracticeTimeSpent }>
    ) => {
      const { timeSpent } = action.payload
      state.timeSpent = timeSpent
      state.timeSpentLoading = false
    },
    updateTimeSpentLoading: (
      state,
      action: PayloadAction<{ loading: boolean }>
    ) => {
      const { loading } = action.payload
      state.timeSpentLoading = loading
    },
    updateFilters: (state, action: PayloadAction<Partial<Filters>>) => {
      const { payload } = action

      state.filters = { ...state.filters, ...payload }
    },
  },
})

export default reducer
export const {
  updateStatus,
  updateLoaded,
  updatePractice,
  updatePracticeList,
  setSelectedPractice,
  updateDialogVisibility,
  setSelectedPracticeByUuid,
  updateSelectedPracticeLoading,
  updatePracticeSummary,
  updatePracticeSummaryLoading,
  updateTimeSpent,
  updateTimeSpentLoading,
  updateFilters,
} = actions

// Selectors
export const practiceSelector = (state: RootState) => state.practice
export const practicesSelector = (state: RootState) => state.practice.practices

export const practiceStatusSelector = (state: RootState) =>
  state.practice.status

export const practicesLoadedSelector = (state: RootState) =>
  state.practice.practicesLoaded

export const practiceDialogOpen = (state: RootState) => state.practice.isOpen

export const practicesCountSelector = (state: RootState) =>
  state.practice.totalCount

export const practicesSubmittedCountSelector = (state: RootState) =>
  state.practice.totalSubmitted

export const selectedPracticeSelector = (state: RootState) =>
  state.practice.selectedPractice

export const selectedPracticeLoadingSelector = createSelector(
  practiceSelector,
  practice => practice.isSelectedPracticeLoading
)

export const practiceFiltersSelector = (state: RootState) =>
  state.practice.filters

export const practiceSummarySelector = (state: RootState) =>
  state.practice.practiceSummary

export const practiceSummaryLoadingSelector = (state: RootState) =>
  state.practice.isPracticeSummaryLoading

export const practiceTimeSpentSelector = (state: RootState) =>
  state.practice.timeSpent

export const practiceTimeSpentLoadingSelector = (state: RootState) =>
  state.practice.timeSpentLoading

export const practiceDataSelector = createSelector(
  selectedPracticeSelector,
  practice => {
    const selectedActivities: CategorisedActivities = {}

    if (practice) {
      practice.activities.forEach(activity => {
        selectedActivities[activity.category]
          ? selectedActivities[activity.category].push(activity)
          : (selectedActivities[activity.category] = [activity])
      })
    }

    return { activities: selectedActivities, practice }
  }
)

export const practiceSummaryRoundsSelector = (state: RootState) =>
  state.practice.practiceSummary.rounds
export const practiceSummaryCategoriesSelector = (state: RootState) =>
  state.practice.practiceSummary.summary.categories
export const practiceSummaryStrokesGainedSelector = (state: RootState) =>
  state.practice.practiceSummary.summary.strokesGained

export const practiceSummaryPracticeResultsSelector = (state: RootState) =>
  state.practice.practiceSummary.practiceResults

export const practiceSummaryComposedDataSelector = createSelector(
  practiceSummaryRoundsSelector,
  practiceSummaryStrokesGainedSelector,
  extractComposedStrokesData
)

export const practiceSummaryStrokesGainedByMetric = (metricId: MetricId) =>
  createSelector(
    practiceSummaryCategoriesSelector,
    practiceSummaryStrokesGainedSelector,
    (categories, strokesGained) => {
      const parentCategories = [
        MetricId.All,
        MetricId.Approach,
        MetricId.Drives,
        MetricId.Short,
        MetricId.Putt,
      ]

      if (!parentCategories.includes(metricId)) {
        const subcategory = categories.find(
          subcategory => subcategory.metricId === metricId
        )

        if (!subcategory) {
          return null
        }

        return subcategory.strokesGained
      } else {
        const parentCategory =
          PARENT_CATEGORY_TYPE_MAP[
            metricId as keyof typeof PARENT_CATEGORY_TYPE_MAP
          ]
        if (parentCategory in strokesGained) {
          const sg = strokesGained[parentCategory as keyof typeof strokesGained]
          return sg.overall
        }
      }
    }
  )

export const practiceSummarySubCategoryData = (category: CategoryType) =>
  createSelector(
    practiceSummaryRoundsSelector,
    practiceSummaryCategoriesSelector,
    (rounds, categories) => {
      const subCategoryData: SubcategoryData = {}

      // Don't calculate subcategory data for CategoryType.All
      if (category === CategoryType.All) {
        return subCategoryData
      }

      categories.forEach(
        ({ metricId, benchmark, progress, strokesGained, type }) => {
          if (type === category) {
            subCategoryData[metricId as MetricId] = {
              data: [],
              progress: progress,
              benchmark: benchmark,
              sgTotal: strokesGained,
            }
          }
        }
      )

      rounds.forEach(round => {
        const roundTitle = getRoundTitle(round)
        const dateValue = new Date(round.datePlayed).getTime()

        if (!round.summary) {
          return
        }

        round.summary.categories.forEach(
          ({ average, metricId, benchmark, strokesGained, rollingAverage }) => {
            const data = {
              average,
              benchmark,
              roundTitle,
              uuid: round.uuid,
              value: strokesGained,
              datePlayed: dateValue,
              rollingAverage,
              roundType: 'Course',
            }

            if (!subCategoryData[metricId as MetricId]) {
              return
            }

            subCategoryData[metricId as MetricId]!.data.push(data)
          }
        )
      })

      return subCategoryData
    }
  )

// Action Creators
export const getPractices =
  (start: number = 0): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)
    const isPlayerPremium = hasActiveSubscription(MembershipLevel.Basic)(state)
    const isPlayerTrialing = userIsTrialingSelector(state)

    // Only get practices for Premium & Trialing users
    // because the 403 response causes logout
    if (isPlayerPremium || isPlayerTrialing) {
      dispatch(updateStatus({ status: PracticeStatus.Loading }))

      const endpoint = isPlayer
        ? 'practice'
        : `overview/player/${playerUuid}/practice`

      try {
        const response = await api.get(endpoint, {
          params: { count: true, start, limit: PAGINATION_LIMIT },
        })
        const practices = getPayloadFromResponse(response)
        const totalCount = response.data?.count || 0

        if (totalCount === 0) {
          throw new Error(PracticeStatus.NoPracticesError)
        }

        const totalSubmitted =
          practices.map((practice: Practice) => practice.hasSubmitted).length ||
          0

        dispatch(updatePracticeList({ practices, totalCount, totalSubmitted }))
        dispatch(updateLoaded({ loaded: true }))
      } catch (error: any) {
        if (isForbiddenOrUnauthorised(error)) {
          authRequestFail(dispatch)
        } else if (error.message === PracticeStatus.NoPracticesError) {
          dispatch(updateStatus({ status: PracticeStatus.NoPracticesError }))
        } else {
          dispatch(updateStatus({ status: PracticeStatus.Error }))
        }
      }
    }
  }

export const getSinglePractice =
  (uuid: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateSelectedPracticeLoading({ isLoading: true }))
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `practice/${uuid}`
      : `overview/player/${playerUuid}/practice/${uuid}`

    try {
      const response = await api.get(endpoint)
      const practice = getSinglePayloadFromResponse(response)

      dispatch(updatePractice({ practice }))
    } catch (error: any) {
      dispatch(updateSelectedPracticeLoading({ isLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error()
      }
    }
  }

export const createPractice = (): AppThunk => async (dispatch, getState) => {
  dispatch(updateStatus({ status: PracticeStatus.CreatingNew }))

  const state = getState()
  // If the user does not have subscription
  // we don't request the practices
  // because the 403 response causes logout
  if (!hasActiveSubscription(MembershipLevel.Basic)(state)) {
    return
  }

  const { isPlayer, playerUuid } = getUserInfo(state)
  const endpoint = isPlayer
    ? 'practice'
    : `overview/player/${playerUuid}/practice`

  try {
    const response = await api.post(endpoint)
    const practice = getSinglePayloadFromResponse(response)

    dispatch(setSelectedPractice({ practice }))
    dispatch(updateStatus({ status: PracticeStatus.Valid }))
  } catch (error: any) {
    dispatch(updateStatus({ status: PracticeStatus.Error }))

    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    } else {
      throw new Error()
    }
  }
}

export const saveExistingPractice =
  (uuid: string, date: Date, activities: ActivityPayload[]): AppThunk =>
  async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `practice/${uuid}`
      : `overview/player/${playerUuid}/practice/${uuid}`

    try {
      const response = await api.put(endpoint, { date, activities })
      const practice = getSinglePayloadFromResponse(response)

      dispatch(updatePractice({ practice }))
      dispatch(getPractices())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error()
      }
    }
  }

export const deletePractice =
  (uuid: string): AppThunk =>
  async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `practice/${uuid}`
      : `overview/player/${playerUuid}/practice/${uuid}`

    try {
      await api.delete(endpoint)
      await dispatch(getPractices())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }

export const getPracticeSummary =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(updatePracticeSummaryLoading({ loading: true }))
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)

    const { timeFilter: time, customTimeRange } = practiceFiltersSelector(state)

    const endpoint = isPlayer
      ? `practice/summary`
      : `overview/player/${playerUuid}/practice/summary`

    const params: PracticeParams = {
      time,
    }
    if (time === PracticeTimeFilter.Custom) {
      params.customTimeRange = customTimeRange
    }

    try {
      const response = await api.get(endpoint, { params })
      const practiceSummary: PracticeSummary =
        getSinglePayloadFromResponse(response)
      dispatch(updatePracticeSummary({ practiceSummary }))
    } catch (error: any) {
      dispatch(updatePracticeSummaryLoading({ loading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        dispatch(updateStatus({ status: PracticeStatus.Error }))
      }
    }
  }

export const getPracticeTimeSpent =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(updateTimeSpentLoading({ loading: true }))
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)

    const { timeFilter: time, customTimeRange } = practiceFiltersSelector(state)

    const endpoint = isPlayer
      ? `practice/time-spent`
      : `overview/player/${playerUuid}/practice/time-spent`

    const params: PracticeParams = {
      time,
    }
    if (time === PracticeTimeFilter.Custom) {
      params.customTimeRange = customTimeRange
    }

    try {
      const response = await api.get(endpoint, { params })

      const timeSpent: PracticeTimeSpent =
        getSinglePayloadFromResponse(response)
      dispatch(updateTimeSpent({ timeSpent }))
    } catch (error) {
      dispatch(updateTimeSpentLoading({ loading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        dispatch(updateStatus({ status: PracticeStatus.Error }))
      }
    }
  }

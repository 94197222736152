import React, { Dispatch, SetStateAction, useMemo } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Form, Formik, FormikConfig } from 'formik'
import { useTranslation, Trans } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import ArrowBack from '@material-ui/icons/KeyboardBackspace'

import useEnum from 'src/hooks/useEnum'
import { breakpoints } from 'src/utils/constants'
import { ArrowForward } from 'src/components/common'
import { XsPaddingZeroContainer } from 'src/modules/common'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { Hole, Par, Lie, Round } from 'src/utils/golfConstants'
import { openToast, getErrorToast } from 'src/store/toastSlice'
import EntryComplete from 'src/modules/rounds/shots/EntryComplete'
import FormToggleButton from 'src/components/inputs/FormToggleButton'
import { fadeVariants, defaultTransition } from 'src/utils/animations'
import ShotFieldStepper from 'src/modules/rounds/shots/ShotFieldStepper'
import { useFlags } from 'launchdarkly-react-client-sdk'

const I18N_KEY = 'RoundsShots'

interface Props {
  currentHole: number
  numberOfHoles: number
  isEditDisabled?: boolean
  roundData: Round | null
  transitionDirection: number
  getPreviousHole: () => void
  setCurrentStep: Dispatch<SetStateAction<number>>
  getNextHole: (values?: Hole | undefined) => Promise<void>
}

interface NextButtonProps {
  $isLastHole: boolean
}

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing(4, 0, 0)};
    padding: ${theme.spacing(6, 0, 4)};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(4, 0)};
    }

    ${theme.breakpoints.down('xs')} {
      margin: 0;
    }
  `
)

const FormContainer = styled(motion.div)(
  ({ theme }) => css`
    width: 300px;
    margin: 0 auto;

    ${theme.breakpoints.down('sm')} {
      max-width: 304px;
    }
    ${theme.breakpoints.down(breakpoints.xxs)} {
      max-width: 232px;
    }
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(4, 6, 0)};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(4, 2, 0)};

      & > button {
        padding: ${theme.spacing(1)}px;
        font-size: ${theme.typography.pxToRem(14)};
      }
    }
  `
)

const NextButton = styled(LoaderButton)<NextButtonProps>(
  ({ $isLastHole }) => css`
    width: ${$isLastHole ? 181 : 135}px;
  `
)

const variants = {
  enter: (direction: number) => ({ opacity: 0, x: direction > 0 ? 50 : -50 }),
  visible: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({ opacity: 0, x: direction > 0 ? -50 : 50 }),
}

const transition = {
  duration: 0.4,
}

const ShotData: React.FC<Props> = ({
  roundData,
  currentHole,
  getNextHole,
  numberOfHoles,
  isEditDisabled = false,
  setCurrentStep,
  getPreviousHole,
  transitionDirection,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { teeLayup } = useFlags()
  const hole = roundData?.holes ? roundData?.holes[currentHole] : undefined

  const par = hole?.par || Par.Four

  const shots = hole?.shots || [
    {
      lie: Lie.Tee,
      distance: '',
      uuid: Math.random(),
    },
  ]

  const { options: lieOptions } = useEnum(Lie, 'Lie')

  const lieOptionsFiltered = (par: Par): { label: string; value: string }[] => {
    if (teeLayup) {
      switch (par) {
        case Par.Three:
          return lieOptions.filter(
            option =>
              option.value !== Lie.TeeDrive && option.value !== Lie.TeeLayup
          )
        case Par.Four:
        case Par.Five:
          var finalLieOptions = lieOptions.filter(
            option => option.value !== Lie.Tee
          )
          return finalLieOptions.map(option => {
            if (option.value === Lie.TeeDrive) {
              return {
                ...option,
                value: Lie.Tee,
              }
            }
            return option
          })
        default:
          return lieOptions
      }
    } else {
      return lieOptions.filter(
        option => option.value !== Lie.TeeDrive && option.value !== Lie.TeeLayup
      )
    }
  }

  const isLastHole = currentHole === numberOfHoles - 1
  const showEntryComplete = currentHole === numberOfHoles
  const isLastEnteredHole =
    !roundData?.holes || currentHole === roundData?.holes.length

  const { options: parOptions } = useEnum(Par, 'Par')

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        par: Yup.string().required(
          t(`${I18N_KEY}.parRequiredErrorMessage`, 'Please select the par')
        ),
        shots: Yup.array().of(
          Yup.object().shape({
            lie: Yup.string().required(
              t(`${I18N_KEY}.lieRequiredErrorMessage`, 'Please enter the lie')
            ),
            distance: Yup.string().required(
              t(
                `${I18N_KEY}.distanceRequiredErrorMessage`,
                'Please enter the shot distance'
              )
            ),
          })
        ),
      }),
    [t]
  )

  const handlePreviousClick = () => {
    if (currentHole > 0) {
      getPreviousHole()
    } else {
      setCurrentStep(0)
    }
  }

  const generateSubmit =
    (dirty: boolean, submitForm: () => Promise<void>) => () => {
      // If !dirty get next hole without trying to save values
      dirty ? submitForm() : getNextHole()
    }

  const handleContinue = () => {
    setCurrentStep(2)
  }

  const formikOptions: FormikConfig<Hole> = {
    initialValues: {
      par,
      shots,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async ({ par, shots }) => {
      if (shots[shots.length - 1].lie === Lie.Penalty) {
        dispatch(
          openToast(
            getErrorToast(
              t(`${I18N_KEY}.penaltyLastError`, 'Last shot cannot be a penalty')
            )
          )
        )
        return
      }

      // Remove random id created for animation
      const payload = {
        par,
        shots: shots.map(({ uuid, ...restShot }) => restShot),
      }
      await getNextHole(payload)
    },
  }

  return (
    <XsPaddingZeroContainer>
      <StyledPaper elevation={0}>
        <Formik {...formikOptions}>
          {({ dirty, values, submitForm, isSubmitting }) => (
            <>
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  exit="exit"
                  initial="enter"
                  animate="visible"
                  variants={fadeVariants}
                  transition={defaultTransition}
                  key={showEntryComplete ? 'entry-complete' : 'shot-entry'}
                >
                  {showEntryComplete ? (
                    <EntryComplete
                      roundUuid={roundData?.uuid as string}
                      recommendedBenchmarkId={
                        roundData?.summary?.recommendedBenchmarkId as string
                      }
                      roundData={roundData}
                    />
                  ) : (
                    <AnimatePresence
                      initial={false}
                      exitBeforeEnter
                      custom={transitionDirection}
                    >
                      <FormContainer
                        exit="exit"
                        initial="enter"
                        animate="visible"
                        variants={variants}
                        transition={transition}
                        custom={transitionDirection}
                        key={`hole-${currentHole + 1}`}
                      >
                        <Form>
                          <Typography gutterBottom variant="h3" align="center">
                            <Trans
                              i18nKey={`${I18N_KEY}.holeNumber`}
                              values={{ currentHole: currentHole + 1 }}
                            >
                              Hole {{ currentHole }}
                            </Trans>
                          </Typography>
                          <FormToggleButton
                            name="par"
                            options={parOptions}
                            disabled={isEditDisabled}
                            data-cy="par-toggle"
                          />
                          <ShotFieldStepper
                            shots={values.shots}
                            par={values.par}
                            lieOptions={lieOptionsFiltered(values.par)}
                            isEditDisabled={isEditDisabled}
                          />
                        </Form>
                      </FormContainer>
                    </AnimatePresence>
                  )}
                </motion.div>
              </AnimatePresence>
              <Divider />
              <ButtonContainer>
                <Button startIcon={<ArrowBack />} onClick={handlePreviousClick}>
                  {currentHole > 0
                    ? t(`${I18N_KEY}.previousHoleButtonLabel`, 'Previous hole')
                    : t(
                        `${I18N_KEY}.roundInformationButtonLabel`,
                        'Round Information'
                      )}
                </Button>
                {showEntryComplete ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleContinue}
                    endIcon={<ArrowForward />}
                  >
                    <Trans i18nKey={`${I18N_KEY}.toSummaryButtonLabel`}>
                      Continue to Detailed Summary
                    </Trans>
                  </Button>
                ) : (
                  <NextButton
                    color="primary"
                    variant="contained"
                    loading={isSubmitting}
                    $isLastHole={isLastHole}
                    endIcon={<ArrowForward />}
                    disabled={!dirty && isLastEnteredHole}
                    onClick={generateSubmit(dirty, submitForm)}
                  >
                    {isLastHole ? (
                      <Trans i18nKey={`${I18N_KEY}.completeEntryLabel`}>
                        Finish Shot Entry
                      </Trans>
                    ) : (
                      <Trans i18nKey={`${I18N_KEY}.nextHoleButtonLabel`}>
                        Next hole
                      </Trans>
                    )}
                  </NextButton>
                )}
              </ButtonContainer>
            </>
          )}
        </Formik>
      </StyledPaper>
    </XsPaddingZeroContainer>
  )
}

export default ShotData

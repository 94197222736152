import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import MuiSpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import MuiSpeedDialAction from '@material-ui/lab/SpeedDialAction'

import {
  totalRoundsSelector,
  updateDialogVisibility as handleRoundsDialogVisibility,
} from 'src/store/roundSlice'
import {
  createPractice,
  updateDialogVisibility as handlePracticeDialogVisibility,
} from 'src/store/practiceSlice'
import {
  totalCourseStrategiesSelector,
  updateDialogVisibility as handleCourseStrategyDialogVisibility,
} from 'src/store/courseStrategySlice'
import { updateDialogVisibility as handleDiscussionDocumentDialogVisibility } from 'src/store/discussionDocumentSlice'
import {
  MembershipLevel,
  breakpoints,
  isChina,
  UserType,
} from 'src/utils/constants'
import TeeGolfBall from 'src/assets/svgComponents/TeeGolfBall'
import UpgradeDialog from 'src/components/membership/UpgradeDialog'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'

import { ReactComponent as CalendarCheckIcon } from 'src/assets/images/CalendarCheck.svg'
import { useFlags } from 'launchdarkly-react-client-sdk'
import CourseStrategyIcon from 'src/assets/svgComponents/CourseStrategyIcon'
import { updateDialogVisibility } from '../../store/ioSlice'
import CirclesIcon from '../../assets/svgComponents/CirclesIcon'
import { coachPermissionsSelector } from '../../store/coachSlice'
import { FeatureType } from '../../utils/roleConstants'
import { userTypeInfoSelector } from '../../store/playerSlice'

const I18N_KEY = 'RoundsDialogButton'

const SpeedDial = styled(MuiSpeedDial)(
  ({ theme }) => css`
    right: 24px;
    bottom: 40px;
    position: fixed;

    .MuiFab-primary {
      width: 60px;
      height: 60px;
      box-shadow: ${theme.shadows[2]};
    }

    .MuiSpeedDialAction-staticTooltipLabel {
      font-weight: 500;
      border-radius: 6px;
      background-color: #fff;
      padding: ${theme.spacing(1)}px;
      box-shadow: ${theme.shadows[2]};
      color: ${theme.palette.primary.main};
      font-size: ${theme.typography.pxToRem(14)};
    }

    .hidden {
      display: none;
    }

    #AddActivity-action-0-label {
      width: 88px;
    }

    #AddActivity-action-1-label {
      width: 96px;
    }

    #AddActivity-action-2-label {
      width: 102px;
    }

    #AddActivity-action-3-label {
      width: 132px;
    }

    #AddActivity-action-4-label {
      width: 225px;
    }

    ${theme.breakpoints.down(breakpoints.intercom)} {
      bottom: 20px;
    }
  `
)

const SpeedDialAction = styled(MuiSpeedDialAction)(
  ({ theme }) => css`
    width: 44px;
    height: 44px;
    color: #fff;
    box-shadow: ${theme.shadows[2]};
    background-color: ${theme.palette.primary.main};

    :hover {
      background-color: ${theme.palette.primary.light};
    }
  `
)

const FloatingActions: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)

  const totalRounds = useSelector(totalRoundsSelector)
  const canAddRoundsAndPractice = useSelector(
    hasActiveSubscription(MembershipLevel.Basic)
  )
  const isPlayerTrialing = useSelector(userIsTrialingSelector)
  const isPlayerPremium = useSelector(hasActiveSubscription())
  const totalCourseStrategies = useSelector(totalCourseStrategiesSelector)
  const isActiveCourseStrategySubscription = useSelector(
    hasActiveSubscription(MembershipLevel.CourseStrategy, false)
  )
  const coachPermissions = useSelector(coachPermissionsSelector)
  const { userType } = useSelector(userTypeInfoSelector)
  const canAddCourseStrategies =
    (isPlayerTrialing && totalCourseStrategies < 2) ||
    isActiveCourseStrategySubscription

  const courseStrategyRoundCheck = totalRounds >= 3

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showLogPractice = totalRounds > 0

  const { courseStrategy, courseStrategyChina } = useFlags()
  const courseStrategyFeatureEnabled = isChina
    ? courseStrategyChina
    : courseStrategy

  const handleClose = () => {
    setIsOpen(false)
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const openRoundsDialog = () => {
    if (!canAddRoundsAndPractice) {
      setUpgradeDialogOpen(true)
      return
    }
    dispatch(handleRoundsDialogVisibility({ isOpen: true }))
    handleClose()
  }

  const openPracticeDialog = async () => {
    if (!canAddRoundsAndPractice) {
      setUpgradeDialogOpen(true)
      return
    }
    dispatch(createPractice())
    dispatch(handlePracticeDialogVisibility({ isOpen: true }))
  }

  const openCourseStrategyDialog = () => {
    if (!canAddCourseStrategies) {
      setUpgradeDialogOpen(true)
      return
    }
    dispatch(handleCourseStrategyDialogVisibility({ isOpen: true }))
    handleClose()
  }

  const openGenerateInsightDialog = () => {
    if (!(isPlayerPremium || isPlayerTrialing)) {
      setUpgradeDialogOpen(true)
      return
    }
    dispatch(updateDialogVisibility({ isOpen: true }))
    handleClose()
  }

  const openDiscussionDocumentDialog = () => {
    dispatch(handleDiscussionDocumentDialogVisibility({ isOpen: true }))
    handleClose()
  }

  const isCoachEligibleForDiscussionDocument = (): boolean => {
    if (userType === UserType.Coach) {
      return coachPermissions.some(
        permission =>
          permission.feature === FeatureType.DiscussionDocument &&
          permission.write
      )
    }
    return false
  }

  const handleUpgradeDialogClose = () => setUpgradeDialogOpen(false)

  return (
    <>
      <SpeedDial
        open={isOpen}
        direction="up"
        onClick={toggleOpen}
        onClose={handleClose}
        icon={<SpeedDialIcon />}
        ariaLabel={t(`${I18N_KEY}.addActivity`, 'Add Activity')}
        data-cy="add-activity"
      >
        <SpeedDialAction
          tooltipOpen
          icon={<TeeGolfBall />}
          onClick={openRoundsDialog}
          tooltipTitle={t(`${I18N_KEY}.addRound`, 'Add Round')}
          data-cy="add-activity-round"
        />
        {showLogPractice ? (
          <SpeedDialAction
            tooltipOpen
            onClick={openPracticeDialog}
            icon={<CalendarCheckIcon />}
            tooltipTitle={t(`${I18N_KEY}.logPractice`, 'Log Practice')}
            data-cy="add-activity-practice"
          />
        ) : (
          <SpeedDialAction
            className={'hidden'}
            onClick={() => {}}
            icon={<CalendarCheckIcon />}
            tooltipTitle=""
          />
        )}
        {courseStrategyFeatureEnabled && courseStrategyRoundCheck ? (
          <SpeedDialAction
            tooltipOpen
            icon={<CourseStrategyIcon />}
            onClick={openCourseStrategyDialog}
            tooltipTitle={t(`${I18N_KEY}.addCourseStrategy`, 'Add Strategy')}
            data-cy="add-activity-strategy"
          />
        ) : (
          <SpeedDialAction
            className={'hidden'}
            icon={<CourseStrategyIcon />}
            onClick={() => {}}
          />
        )}
        {totalRounds >= 5 ? (
          <SpeedDialAction
            tooltipOpen
            icon={<CirclesIcon />}
            onClick={openGenerateInsightDialog}
            tooltipTitle={t(`${I18N_KEY}.generateInsight`, 'Generate Insight')}
          />
        ) : (
          <SpeedDialAction
            className={'hidden'}
            icon={<CirclesIcon />}
            onClick={() => {}}
          />
        )}
        {isCoachEligibleForDiscussionDocument() ? (
          <SpeedDialAction
            tooltipOpen
            icon={<NoteAddIcon />}
            onClick={openDiscussionDocumentDialog}
            tooltipTitle={t(
              `${I18N_KEY}.generateDiscussionDocument`,
              'Generate Discussion Document'
            )}
          />
        ) : (
          <SpeedDialAction
            className={'hidden'}
            icon={<NoteAddIcon />}
            onClick={() => {}}
            tooltipTitle=""
          />
        )}
      </SpeedDial>
      <UpgradeDialog
        open={upgradeDialogOpen}
        onClose={handleUpgradeDialogClose}
        onUpgradeOpen={handleUpgradeDialogClose}
      />
    </>
  )
}

export default FloatingActions
